import {
  getPrimaryCalendarColor,
  shouldCalendarBeShown,
  removeDuplicatesFromArray,
  isEmailAGroupContact,
  isValidTimeZone,
  isInt,
  handleError,
} from "../services/commonUsefulFunctions";
import GoogleColors from "../services/googleColors";
import { DEFAULT_PRIMARY_CALENDAR_COLOR } from "../services/globalVariables";
import { EDITABLE_ROLES, OWNER_ROLE } from "../services/googleCalendarService";
import {
  getMatchingExecutiveCalendar,
  getMatchingExecutiveUserFromCalendar,
  isCalendarExecutiveCalendar,
  isUserDelegatedUser,
  isUserExecutiveUser,
  isUserMaestroUser,
  shouldHideDelegatedUser,
  shouldHideDelegatedUserCalendar,
} from "../services/maestroFunctions";
import {
  addCalendarSelected,
  getCalendarBackgroundColor,
  getCalendarColorID,
  getCalendarDefaultReminders,
  getCalendarEditRole,
  getCalendarProviderId,
  getCalendarIsPrimary,
  getCalendarIsSelected,
  getCalendarSummary,
  getCalendarUserCalendarID,
  getCalendarEmail,
  getCalendarColorHex,
  getCalendarObject,
  getCalendarAllowedMeetingProviders,
  getCalendarOwnerEmail,
  isMatchingCalendarEmailOrUserEmail,
  getCalendarUpdatedTime,
} from "../services/calendarAccessors";
import { getSelfAttendingStatus } from "./eventFunctions";
import { getEventTitle, getEventUserCalendarID, getEventUserEventID } from "../services/eventResourceAccessors";
import { isVersionV2 } from "../services/versionFunctions";
import { CALENDAR_PROVIDERS, POPULAR_TIME_ZONE } from "./vimcalVariables";
import {
  DEFAULT_OUTLOOK_CALENDAR_HEX,
  PROVIDER_TYPES,
  convertOutlookConferencingToHumanReadable,
} from "./outlookFunctions";
import {
  getMatchingExecUserFromAllUsers,
  getMatchingUserFromAllUsers,
  getUserEmail,
  getUserName,
  isSameUser,
} from "./userFunctions";
import { immutablySortArray, isEmptyArray } from "./arrayFunctions";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
} from "../services/typeGuards";
import {
  equalAfterTrimAndLowerCased,
  formatEmail,
  isSameEmail,
  lowerCaseAndTrimString,
} from "./stringFunctions";
import { UPDATE_CALENDAR_ORDER } from "./endpoints";
import { constructRequestURLV2 } from "../services/api";
import { fetcherPost } from "../services/fetcherFunctions";

export function determineCalendarColor(calendar) {
  if (isEmptyObjectOrFalsey(calendar)) {
    return DEFAULT_PRIMARY_CALENDAR_COLOR;
  }

  const calendarColorHex = getCalendarColorHex(calendar);

  if (calendarColorHex) {
    return calendarColorHex;
  } else if (getCalendarIsPrimary(calendar)) {
    return getPrimaryCalendarColor(calendar);
  }

  const colorID = getCalendarColorID(calendar);
  const color =
    colorID &&
    GoogleColors.calendar[colorID] &&
    GoogleColors.calendar[colorID].background;
  const rawJsonBackgroundColor = getCalendarBackgroundColor(calendar);

  if (
    rawJsonBackgroundColor &&
    GoogleColors["old_calendar_colors"][colorID] &&
    GoogleColors["old_calendar_colors"][colorID].background !==
      rawJsonBackgroundColor
  ) {
    // if the given calendar background is the same as the old events color -> return new color from colorId index
    return rawJsonBackgroundColor;
  }

  return color || rawJsonBackgroundColor || DEFAULT_PRIMARY_CALENDAR_COLOR;
}

export function getUserCalendar(allCalendars, email) {
  if (isEmptyObjectOrFalsey(allCalendars) || !email) {
    return {};
  }

  let matchingCalendars = {};
  Object.keys(allCalendars).forEach((k) => {
    if (
      equalAfterTrimAndLowerCased(getCalendarUserEmail(allCalendars[k]), email)
    ) {
      matchingCalendars[k] = allCalendars[k];
    }
  });

  return matchingCalendars;
}

export function getUserPrimaryCalendar({ allCalendars, email }) {
  if (isEmptyObjectOrFalsey(allCalendars) || !email) {
    return {};
  }

  const matchingUserCalendarId = Object.keys(allCalendars).find((k) => {
    return (
      getCalendarUserEmail(allCalendars[k]) === email &&
      getCalendarIsPrimary(allCalendars[k])
    );
  });

  return allCalendars[matchingUserCalendarId];
}

export function createUpdatedAllCalendars(param) {
  const {
    responseAllCalendars,
    allCalendars,
    userEmail, // so we know which user this calendar is from,
    isCleanRefresh,
    currentUser = {},
    isNewUserSync = false,
    allLoggedInUsers,
    masterAccount,
    whereFrom = "undefined", // used for debugging so we know what's calling this from
  } = param;
  let updatedAllCalendars = {};
  const selectedCalendarsIndex =
    getSelectedCalendarIndexFromAllCalendars(allCalendars);

  let selectedOnCalendars = []; // array of calendars that are selected - on
  Object.keys(selectedCalendarsIndex).forEach((userCalendarID) => {
    if (selectedCalendarsIndex[userCalendarID]) {
      selectedOnCalendars = selectedOnCalendars.concat(userCalendarID);
    }
  });

  if (!isEmptyObjectOrFalsey(responseAllCalendars)) {
    Object.keys(responseAllCalendars).forEach((k) => {
      if (
        !isValidCalendar(responseAllCalendars[k]) ||
        !shouldCalendarBeShown(responseAllCalendars[k]) ||
        isGoogleBirthdayCalendar(responseAllCalendars[k])
      ) {
        return;
      }

      const shouldSkipUpdatingCalendar = () => {
        if (isNewUserSync) {
          return false;
        }
        const responseCalendarUpdatedAt = getCalendarUpdatedTime(responseAllCalendars[k]);
        const currentCalendarUpdatedAt = getCalendarUpdatedTime(allCalendars[k]);
        if (!responseCalendarUpdatedAt && !currentCalendarUpdatedAt) {
          return false;
        }
        if (responseCalendarUpdatedAt && !currentCalendarUpdatedAt) {
          return false;
        }
        if (!responseCalendarUpdatedAt && currentCalendarUpdatedAt) {
          // if response doesn't have updated at -> skip
          return true;
        }

        // race condition where current was updated after.
        return currentCalendarUpdatedAt > responseCalendarUpdatedAt;
      };

      if (shouldSkipUpdatingCalendar() && isValidCalendar(allCalendars[k])) {
        // use existing
        updatedAllCalendars[k] = allCalendars[k];
      } else {
        updatedAllCalendars[k] = responseAllCalendars[k];
      }

      updatedAllCalendars[k].userEmail = userEmail;

      const getDefaultIsCalendarSelected = (k) => {
        // for refresh and new account login
        if (shouldHideDelegatedUserCalendar({
          calendar: updatedAllCalendars[k],
          allCalendars,
          allLoggedInUsers,
        })) {
          // always hide delegated user calendars
          return false;
        }
        if (isUserMaestroUser(masterAccount)) {
          const matchingExecutiveCalendar = getMatchingExecutiveCalendar({
            allCalendars,
            user: currentUser,
          });
          if (isSameCalendarUserCalendarID(updatedAllCalendars[k], matchingExecutiveCalendar)) {
            // if it's the same calendar as the executive calendar on refresh
            return true;
          }
        }

        // otherwise, toggle on primary calendar
        if (getCalendarIsPrimary(updatedAllCalendars[k])
          && isMatchingCalendarEmailOrUserEmail(
            updatedAllCalendars[k],
            getUserEmail(currentUser),
          )) {
          // toggle on primary calendar for the user
          return true;
        }
        if (selectedCalendarsIndex[k]) {
          return true;
        }
        return false;
      };

      // when new user is logged in
      // on new user initial sync -> set main calendars to selected
      // on login to new user -> keep selected of previous login.
      // for maestro limited users -> only select the new calendar (proxy only returns one calendar)
      // Required to prevent other calendars from being selected (each sync enters if we only have limited access check
      const matchingExecutiveCalendar = isUserMaestroUser(masterAccount) ? getMatchingExecutiveCalendar({
        allCalendars,
        user: currentUser,
      }) : null;

      if (isNewUserSync) {
        updatedAllCalendars[k] = addCalendarSelected({
          calendar: updatedAllCalendars[k],
          selected: getDefaultIsCalendarSelected(k),
        });
      } else if (selectedOnCalendars.length === 0 && isCleanRefresh) {
        updatedAllCalendars[k] = addCalendarSelected({
          calendar: updatedAllCalendars[k],
          selected: getDefaultIsCalendarSelected(k),
        });
      } else if (matchingExecutiveCalendar
        && isCleanRefresh
        && shouldHideDelegatedUserCalendar({
          calendar: updatedAllCalendars[k],
          allCalendars,
          allLoggedInUsers,
        }) // if we should be hiding the delegated user
        && isSameCalendarProviderID(updatedAllCalendars[k], matchingExecutiveCalendar)
        && selectedOnCalendars.includes(k)
      ) {
        // for backwards compatibility
        // if previously had multiple calendars selected -> remove old delegated calendar
        selectedOnCalendars = selectedOnCalendars.filter(id => id !== k); // remove old delegated calendar on delegated user
        updatedAllCalendars[k] = addCalendarSelected({
          calendar: updatedAllCalendars[k],
          selected: false,
        });
      } else {
        updatedAllCalendars[k] = addCalendarSelected({
          calendar: updatedAllCalendars[k],
          selected: selectedCalendarsIndex[k] || false,
        });
      }
    });
  }

  if (!isEmptyObjectOrFalsey(allCalendars)) {
    if (isCleanRefresh) {
      let filteredCalendars = {};
      Object.keys(allCalendars).forEach((k) => {
        const calendar = allCalendars[k];
        if (getCalendarUserEmail(calendar) !== userEmail) {
          // only add if it's a different email on complete sync
          filteredCalendars[k] = calendar;
        }
      });
      updatedAllCalendars = { ...filteredCalendars, ...updatedAllCalendars };
    } else {
      updatedAllCalendars = { ...allCalendars, ...updatedAllCalendars };
    }
  }

  return updatedAllCalendars;
}

export function parseSyncResponseCalendars(
  calendarList,
  lastSyncedAtUtc,
  allCalendars,
) {
  // also need to add last_synced_at_utc
  if (isEmptyArrayOrFalsey(calendarList)) {
    return {
      updatedAllCalendars: {},
      calendarContacts: [],
      primaryCalendarCount: 0,
    };
  }

  let responseAllCalendars = {};
  // contacts info of calendars {email: google_id: name: summary};
  let calendarContacts = [];

  let primaryCalendarCount = 0;
  calendarList.forEach((c) => {
    if (c.primary) {
      // this is the one place where we can use .primary since this is before we put .calendar into allCalendars
      primaryCalendarCount += 1;
    }

    calendarContacts = calendarContacts.concat({
      email: c.google_id,
      name: c.summary_override || c.summary,
    });

    const calendarUserCalendarID = getEventUserCalendarID(c);
    responseAllCalendars[calendarUserCalendarID] = {};
    responseAllCalendars[calendarUserCalendarID].calendar = c;

    if (isCalendarSelected(allCalendars[calendarUserCalendarID])) {
      responseAllCalendars[calendarUserCalendarID].calendar.last_synced_at_utc =
        lastSyncedAtUtc;
      responseAllCalendars[calendarUserCalendarID].calendar.last_synced_at =
        lastSyncedAtUtc;
      responseAllCalendars[calendarUserCalendarID].calendar.lastSyncedAt =
        lastSyncedAtUtc;
      responseAllCalendars[calendarUserCalendarID].lastSyncedAt =
        lastSyncedAtUtc;
    }
  });

  return {
    responseAllCalendars,
    calendarContacts,
    primaryCalendarCount,
  };
}

function doesCalendarHaveOrder(calendar) {
  const index = getCalendarUserOrder(calendar);
  return !isNullOrUndefined(index) && index >= 0;
}

function sortCalendars(calendars) {
  if (isEmptyArrayOrFalsey(calendars)) {
    return [];
  }

  return immutablySortArray(calendars, (a, b) => {
    if (!doesCalendarHaveOrder(a) && doesCalendarHaveOrder(b)) {
      return 1;
    }
    if (doesCalendarHaveOrder(a) && !doesCalendarHaveOrder(b)) {
      return -1;
    }
    if (doesCalendarHaveOrder(a) && doesCalendarHaveOrder(b)) {
      const orderA = getCalendarUserOrder(a);
      const orderB = getCalendarUserOrder(b);
      return orderA - orderB;
    }
    const nameA = lowerCaseAndTrimString(
      getCalendarSummary(a) || getCalendarProviderId(a),
    );
    const nameB = lowerCaseAndTrimString(
      getCalendarSummary(b) || getCalendarProviderId(b),
    );
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
}

export function getDuplicateCalendarNames(userCalendar, emailToNameIndex) {
  let duplicateNames = [];
  let allNames = [];

  userCalendar.forEach((calendar) => {
    const email = getCalendarProviderId(calendar);
    const name =
      calendar.calendar.summary_override ||
      emailToNameIndex[email] ||
      getCalendarSummary(calendar);
    if (!name) {
      return;
    }

    if (allNames.includes(name)) {
      duplicateNames = duplicateNames.concat(name);
    } else if (!isEmailAGroupContact(email)) {
      // do not add emails like contacts, birthdays, etc
      allNames = allNames.concat(name);
    }
  });

  return duplicateNames;
}

export function getCalendarName({
  calendar,
  emailToNameIndex,
  currentUser,
  masterAccount,
}) {
  if (calendar?.calendar?.name_override) {
    return calendar?.calendar?.name_override;
  }
  if (calendar?.calendar?.summary_override) {
    return calendar.calendar.summary_override;
  }
  if (calendar?.calendar?.name) {
    return calendar.calendar.name;
  }
  if (currentUser?.email === getCalendarProviderId(calendar)) {
    const { fullName } = getUserName({ user: currentUser, masterAccount });
    if (fullName) {
      return fullName;
    }
  }

  const email = getCalendarProviderId(calendar);
  const calendarSummary = getCalendarSummary(calendar);

  if (emailToNameIndex && emailToNameIndex[calendarSummary]) {
    // sometimes calendar summary can be email
    return emailToNameIndex[calendarSummary];
  } else if (calendarSummary) {
    return calendarSummary;
  } else if (emailToNameIndex && emailToNameIndex[email]) {
    return emailToNameIndex[email];
  }

  if (email) {
    return email.toLowerCase();
  }

  return email;
}

export function isCalendarSelected(calendar) {
  if (isEmptyObjectOrFalsey(calendar)) {
    return false;
  }

  return getCalendarIsSelected(calendar) ?? false;
}

export function isValidCalendar(calendar) {
  if (isVersionV2()) {
    return (
      !!getCalendarUserCalendarID(calendar) && !!getCalendarProviderId(calendar)
    );
  }

  return (
    !!getCalendarUserCalendarID(calendar) &&
    !!getCalendarProviderId(calendar) &&
    !!calendar.calendar.raw_json
  );
}

export function getActiveCalendarEmailsFromAllCalendars({ allCalendars }) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  let activeCalendarEmails = [];
  Object.keys(allCalendars).forEach((k) => {
    if (isCalendarSelected(allCalendars[k])) {
      activeCalendarEmails = activeCalendarEmails.concat(
        getCalendarProviderId(allCalendars[k]),
      );
    }
  });

  return activeCalendarEmails;
}

export function getActiveCalendarsIDsFromAllCalendars({
  allCalendars,
  currentUserEmail,
  isInitialSync = false,
  isUserDelegatedUser = false,
}) {
  return getActiveCalendarsFromAllCalendars({
    allCalendars,
    currentUserEmail,
    isInitialSync,
    isUserDelegatedUser,
  }).map((c) => getCalendarUserCalendarID(c));
}

export function getActiveCalendarsForUser({
  allCalendars,
  userEmail,
  upcomingUserCalendarIDs,
  skipPrimaryCheck = false, // do not add if primary calendar
}) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  return Object.values(allCalendars).filter((calendar) => {
    if (!isSameEmail(getCalendarUserEmail(calendar), userEmail)) {
      return false;
    }
    if (isCalendarSelected(calendar)) {
      return true;
    } else if (!skipPrimaryCheck && getCalendarIsPrimary(calendar)) {
      return true;
    } else if (upcomingUserCalendarIDs?.includes(getCalendarUserCalendarID(calendar))) {
      return true;
    }
    return false;
  });
}

export function getActiveCalendarsFromAllCalendars({
  allCalendars,
  currentUserEmail,
  isInitialSync = false,
  isUserDelegatedUser = false,
}) {
  try {
    if (isEmptyObjectOrFalsey(allCalendars)) {
      return [];
    }
    return Object.values(allCalendars).filter((calendar) => {
      if (isUserDelegatedUser) {
        return true;
      }
      if (isCalendarSelected(calendar)) {
        return true;
      }
      if (
        getCalendarIsPrimary(calendar)
        && isSameEmail(getCalendarUserEmail(calendar), currentUserEmail)
        && isInitialSync
      ) {
        // if it's primary and the user email matches, add it
        return true;
      }
      return false;
    });
  } catch (error) {
    handleError(error);
    return [];
  }
}

export function getCalendarsFromCalendarID(calendarIDs, allCalendars) {
  // calendarIDs comes in as an array
  if (isEmptyArrayOrFalsey(calendarIDs)) {
    return {};
  }

  let filteredCalendars = {};
  Object.keys(allCalendars).forEach((k) => {
    if (calendarIDs.includes(k)) {
      filteredCalendars[k] = allCalendars[k];
    }
  });

  return filteredCalendars;
}

export function getMatchingCalendarWithCalendarOwnerFromEmail({
  email,
  allCalendars,
  checkForPrimaryOnly,
  checkForMatchingCalendarOnProviderID,
}) {
  if (!email || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  return Object.values(allCalendars)?.find((calendar) => {
    if (checkForPrimaryOnly) {
      return (
        getCalendarIsPrimary(calendar) &&
        isSameEmail(getCalendarOwnerEmail(calendar), email)
      );
    }
    if (
      checkForMatchingCalendarOnProviderID &&
      isSameEmail(getCalendarProviderId(calendar), email)
    ) {
      return true;
    }
    return isSameEmail(getCalendarOwnerEmail(calendar), email);
  });
}

export function getFirstMatchingEditableCalendarFromEmail({
  allCalendars,
  email,
}) {
  if (!email || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }
  const writableCalendars = filterForAllWritableCalendars(allCalendars);
  const calendars = Object.values(writableCalendars);

  const doesCalendarBelongToEmail = (calendar) => {
    if (isGoogle(calendar)) {
      return isSameEmail(getCalendarOwnerEmail(calendar), email);
    }
    return isSameEmail(getCalendarOwnerEmail(calendar), email) || isSameEmail(getCalendarUserEmail(calendar), email);
  };

  const primaryCalendars = calendars.find(calendar => getCalendarIsPrimary(calendar) && doesCalendarBelongToEmail(calendar));
  const secondaryCalendar = calendars.find(calendar => !getCalendarIsPrimary(calendar) && isSameEmail(getCalendarEmail(calendar), email));
  if (primaryCalendars) {
    if (!isCalendarSelected(primaryCalendars) && secondaryCalendar) {
      return secondaryCalendar;
    }
    return primaryCalendars;
  }

  const matchingCalendarToEmail = calendars.find(calendar => doesCalendarBelongToEmail(calendar));
  if (matchingCalendarToEmail) {
    return matchingCalendarToEmail;
  }
  return null;
}

// TODO: update
export function getCalendarFromEmail({
  email,
  allCalendars,
  allLoggedInUsers,
  masterAccount,
}) {
  try {
    if (!email || isEmptyObjectOrFalsey(allCalendars)) {
      return null;
    }
    const isVimcalEAUser = isUserMaestroUser(masterAccount);
    const filteredCalendars = Object.values(allCalendars).filter((calendar) => !shouldHideDelegatedUserCalendar({calendar, allCalendars, allLoggedInUsers}));

    let matchingCalendar; // do not use .find here since we want to find the best match, not the first match.
    filteredCalendars.find((calendar) => {
      if (isVimcalEAUser) {
        // return calendar;
        if (!getCalendarIsPrimary(matchingCalendar)) {
          // don't override primary calendar
          const isExecCalendar = isCalendarExecutiveCalendar({calendar, allLoggedInUsers});
          if (isExecCalendar && isSameEmail(getCalendarProviderId(calendar), email)) {
            // only set it if it's an exec calendar and the email matches
            matchingCalendar = calendar;
            return;
          }
        }
      }
      if (!isMatchingCalendarEmailOrUserEmail(calendar, email)) {
        return;
      }
      if (!matchingCalendar) {
        matchingCalendar = calendar;
        return;
      }
      if (
        getCalendarIsPrimary(calendar) &&
        isSameEmail(getCalendarProviderId(calendar), email)
      ) {
        matchingCalendar = calendar;
        return;
      }
      if (
        getCalendarIsPrimary(calendar) &&
        !getCalendarIsPrimary(matchingCalendar)
      ) {
        // set if it's primary calendar
        matchingCalendar = calendar;
      }
    });
    return matchingCalendar;
  } catch (error) {
    handleError(error);
    return null;
  }
}

export function getCurrentUserDefaultColor({currentUserEmail, allCalendars, allLoggedInUsers, masterAccount}) {
  const currentUserCalendar = getCalendarFromEmail({
    email: currentUserEmail,
    allCalendars,
    allLoggedInUsers,
    masterAccount,
  });
  return determineCalendarColor(currentUserCalendar);
}

export function getUserCalendarIDFromEmail({email, allCalendars, allLoggedInUsers, masterAccount}) {
  if (!email || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  const matchingCalendar = getCalendarFromEmail({
    email,
    allCalendars,
    allLoggedInUsers,
    masterAccount,
  });
  return getCalendarUserCalendarID(matchingCalendar);
}

export function isOwnerOfCalendar(allCalendars, userCalendarID) {
  return getCalendarEditRole(allCalendars[userCalendarID]) === OWNER_ROLE;
}

export function hasWriteAccessToUserCalendarID(userCalendarID, allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars) || !userCalendarID) {
    return false;
  }

  return doesCalendarHaveEditableRole(allCalendars[userCalendarID]);
}

export function getAllEditableCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return {};
  }

  let writableCalendars = {};
  Object.keys(allCalendars).forEach((k) => {
    if (doesCalendarHaveEditableRole(allCalendars[k])) {
      writableCalendars[k] = allCalendars[k];
    }
  });

  return writableCalendars;
}

export function getMainCalendarEmails(mainCalendarUserIds, allCalendars) {
  if (
    isEmptyArrayOrFalsey(mainCalendarUserIds) ||
    isEmptyObjectOrFalsey(allCalendars)
  ) {
    return [];
  }

  let mainCalendarEmails = [];
  Object.keys(allCalendars).forEach((k) => {
    if (mainCalendarUserIds.includes(k)) {
      mainCalendarEmails = mainCalendarEmails.concat(
        getCalendarProviderId(allCalendars[k]),
      );
    }
  });

  return mainCalendarEmails;
}

export function createEmailsFromActiveCalendars(activeCalendars) {
  if (isEmptyObjectOrFalsey(activeCalendars)) {
    return [];
  }

  const emails = [];

  Object.keys(activeCalendars).forEach((k) => {
    emails.push(getCalendarProviderId(activeCalendars[k]));
  });

  return emails;
}

export function getAllCalendarUserCalendarIDs(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  return Object.keys(allCalendars);
}

export function getUserEmailFromListOfCalendarIDs(
  userCalendarIDList,
  allCalendars,
) {
  // given list of user calendar ids -> emails
  if (isEmptyArrayOrFalsey(userCalendarIDList)) {
    return [];
  }

  const emailList = [];
  Object.keys(allCalendars).forEach((k) => {
    if (userCalendarIDList.includes(k)) {
      emailList.push(getCalendarUserEmail(allCalendars[k]));
    }
  });

  return removeDuplicatesFromArray(emailList);
}

export function getHumanReadableEmailFromUserCalendarID(
  userCalendarID,
  allCalendars,
) {
  if (!userCalendarID || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  return getCalendarEmail(allCalendars[userCalendarID]);
}

export function getEmailFromUserCalendarID(userCalendarID, allCalendars) {
  if (!userCalendarID || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  return getCalendarProviderId(allCalendars[userCalendarID]);
}

export function doesMainCalendarsIncludeUserCalendarID(
  mainCalendars,
  userCalendarID,
) {
  if (isEmptyArrayOrFalsey(mainCalendars) || !userCalendarID) {
    return false;
  }

  return mainCalendars.includes(userCalendarID);
}

export function getSecondaryCalendarsFromAllCalendars(allCalendars) {
  try {
    if (isEmptyObjectOrFalsey(allCalendars)) {
      return [];
    }
    return Object.values(allCalendars).filter(calendar => !getCalendarIsPrimary(calendar));
  } catch (error) {
    return [];
  }
}

export function getAllPrimaryCalendarsFromAllCalendars(allCalendars) {
  try {
    if (isEmptyObjectOrFalsey(allCalendars)) {
      return [];
    }
    const primaryCalendars = [];
    Object.values(allCalendars).forEach((calendar) => {
      if (getCalendarIsPrimary(calendar)) {
        primaryCalendars.push(calendar);
      }
    });

    return primaryCalendars;
  } catch (error) {
    return [];
  }
}

export function getAllPrimaryCalendarsIDsFromAllCalendars(allCalendars) {
  const primaryCalendars = getAllPrimaryCalendarsFromAllCalendars(allCalendars);
  return primaryCalendars.map(calendar => getCalendarUserCalendarID(calendar));
}

export function getEventAttendeeStatus(event, allCalendars) {
  if (isEmptyObjectOrFalsey(event) || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  const matchingCalendar = allCalendars[getEventUserCalendarID(event)];
  const email = getCalendarProviderId(matchingCalendar);
  return getSelfAttendingStatus(event, email);
}

export function getOrderedAllCalendars({allCalendars, currentUserEmail, allLoggedInUsers, masterAccount}) {
  // returns array inorder to keep order and have current user calendar at the top
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  const currentUserCalendar = getCalendarFromEmail({
    email: currentUserEmail,
    allCalendars,
    allLoggedInUsers,
    masterAccount,
  });
  const allCalendarsArray = Object.values(allCalendars).filter(calendar => shouldCalendarBeShown(calendar));
  if (
    allCalendarsArray.some(
      (calendar) =>
        doesCalendarHaveOrder(calendar),
    )
  ) {
    // if a calendar has order -> do not put current user calendar at the top
    return sortCalendars(allCalendarsArray);
  }

  let allCalendarsExceptCurrentUserCalendar = [];

  allCalendarsArray.forEach((calendar) => {
    if (
      getCalendarUserCalendarID(calendar) ===
        getCalendarUserCalendarID(currentUserCalendar)
    ) {
      // skip so we don't add the current user calendar twice
      // we skip hidden calendars
      return;
    }

    allCalendarsExceptCurrentUserCalendar =
      allCalendarsExceptCurrentUserCalendar.concat(calendar);
  });
  const sortedCalendars = sortCalendars(allCalendarsExceptCurrentUserCalendar);

  if (currentUserCalendar) {
    return [currentUserCalendar].concat(sortedCalendars); // current user calendar comes first
  } else {
    return sortedCalendars;
  }
}

export function getUserEmailFromEvent(event, allCalendars) {
  // get the user account that the event belongs to
  return getUserEmailFromUserCalendarID(
    getEventUserCalendarID(event),
    allCalendars,
  );
}

export function getMatchingUserFromEvent({
  event,
  allCalendars,
  allLoggedInUsers,
}) {
  const matchingUserEmail = getUserEmailFromEvent(event, allCalendars);
  return allLoggedInUsers?.find((user) =>
    isSameEmail(getUserEmail(user), matchingUserEmail),
  );
}

export function getUserEmailFromEventWithCurrentUserBackup({
  event,
  allCalendars,
  currentUser,
}) {
  return (
    getUserEmailFromUserCalendarID(
      getEventUserCalendarID(event),
      allCalendars,
    ) ?? currentUser?.email
  );
}

export function getUserEmailFromUserCalendarID(userCalendarID, allCalendars) {
  return getCalendarUserEmail(allCalendars[userCalendarID]);
}

export function isUserCalendarIDFromPrimaryCalendar(
  userCalendarID,
  allCalendars,
) {
  const matchingCalendar = allCalendars[userCalendarID];
  return getCalendarIsPrimary(matchingCalendar);
}

export function getAllUserCalendarIDWithDefaultReminders(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  let userCalendarIDWithDefaultReminders = [];
  Object.keys(allCalendars).forEach((k) => {
    if (getCalendarDefaultReminders(allCalendars[k])?.length > 0) {
      userCalendarIDWithDefaultReminders =
        userCalendarIDWithDefaultReminders.concat(k);
    }
  });

  return userCalendarIDWithDefaultReminders;
}

export function isCalendarSecondaryAccount( // if it's one of the other logged in calendars
  calendar,
  allLoggedInUsers,
  currentUser,
) {
  if (
    isEmptyObjectOrFalsey(calendar) ||
    isEmptyObjectOrFalsey(calendar.calendar) ||
    isEmptyArray(allLoggedInUsers)
  ) {
    return;
  }
  const email = getCalendarOwnerEmail(calendar);

  const loggedInUserEmails = allLoggedInUsers
    .filter(
      (user) => !isSameEmail(getUserEmail(user), getUserEmail(currentUser)),
    )
    .map((user) => getUserEmail(user));
  return loggedInUserEmails.includes(email);
}

export function isCalendarConvertableToSecondary({
  allCalendars,
  allLoggedInUsers,
  calendar,
  currentUser,
  masterAccount,
}) {
  if (
    isEmptyObjectOrFalsey(allCalendars) ||
    isEmptyObjectOrFalsey(allLoggedInUsers) ||
    isEmptyObjectOrFalsey(calendar) ||
    isEmptyObjectOrFalsey(masterAccount)
  ) {
    return false;
  }
  if (!isUserMaestroUser(masterAccount)) {
    return false;
  }
  if (!doesCalendarHaveEditableRole(calendar)) {
    return false;
  }

  // Need this since it's reliant on the backend
  // note: if the stripe email is different (@vimcal vs @weveapp), then it won't get added as secondary account
  // const isCalendarFromMasterAccount = Object.keys(
  //   getUserCalendar(allCalendars, masterAccount.stripe_email)
  // ).includes(getCalendarUserCalendarID(calendar));
  const calendarOwnerEmail = getCalendarOwnerEmail(calendar);
  const isCalendarGroupCalendar =
    getCalendarProviderId(calendar)?.includes(".google.com");
  const calendarUserEmail = getCalendarUserEmail(calendar);

  return (
    (!isSameEmail(calendarOwnerEmail, getUserEmail(currentUser)) &&
      !isCalendarGroupCalendar &&
      // Make sure the user is still logged into the account that has access to the calendar.
      allLoggedInUsers.some(
        (user) => getUserEmail(user) === calendarUserEmail,
      )) ||
    isCalendarSecondaryAccount(calendar, allLoggedInUsers, currentUser)
  );
}

export function getOrSetSelectedCalendarsForNewUser({
  allCalendars,
  currentUserEmail,
  isUserDelegatedUserBool,
}) {
  let userCalendarOverrides = {};

  if (isUserDelegatedUserBool) {
    /* Deselect all calendars except primary if user is a delegated user */
    /* Loop through all calendars */
    Object.keys(allCalendars).forEach((k) => {
      /* Set the override for the current calendar id to false unless it's the primary calendar of the delegated account */
      userCalendarOverrides[k] =
        isSameEmail(getCalendarUserEmail(allCalendars[k]), currentUserEmail) &&
        getCalendarIsPrimary(allCalendars[k]);
    });
  } else {
    /* Keep current selection and add primary calendar of new account */
    /* Loop through all calendars */
    Object.keys(allCalendars).forEach((k) => {
      /* Set the override for the current calendar id to false unless it's one of the following */
      /* It's the primary calendar of the new account */
      /* It's currently selected */
      userCalendarOverrides[k] =
        isCalendarSelected(allCalendars[k]) ||
        (getCalendarUserEmail(allCalendars[k]) === currentUserEmail &&
          getCalendarIsPrimary(allCalendars[k]));
    });
  }

  /* Return the overrides object (key: value is calendarId: true || falsey) */
  return userCalendarOverrides;
}

export function getCalendarUserEmail(calendar) {
  return formatEmail(calendar?.userEmail || calendar?.user_email);
}

export function getCalendarFromUserCalendarID({
  userCalendarID,
  allCalendars,
}) {
  return allCalendars?.[userCalendarID];
}

// TODO: update
export function getCalendarFromProviderID({
  allCalendars,
  providerID,
  selectedUser, // the only place we don't pass this is in the eventExpandedView::renderTitle and eventFormContainer::getCalendar
  allLoggedInUsers,
  masterAccount,
}) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }
  const isVimcalEAUser = isUserMaestroUser(masterAccount);

  const matchingCalendars = Object.values(allCalendars).filter((calendar) => {
    if (isVimcalEAUser && shouldHideDelegatedUserCalendar({calendar, allCalendars, allLoggedInUsers})) {
      return false;
    }
    return getCalendarProviderId(calendar) === providerID;
  });

  let matchingCalendar;
  if (selectedUser) {
    const isExecUser = isUserExecutiveUser({user: selectedUser});
    matchingCalendar = matchingCalendars.find((c) => {
      if (isVimcalEAUser) {
        const isExecCalendar = isCalendarExecutiveCalendar({calendar: c, allLoggedInUsers});
        if (isSameEmail(
          getCalendarUserEmail(c),
          getUserEmail(selectedUser),
        )) {
          // since we already filtered out the delegated users, we can return true if calendar email is the same as user email
          // this is only true for magic link
          return true;
        }

        const matchingExecUserCalendar = getMatchingExecutiveUserFromCalendar({calendar: c, allLoggedInUsers});
        if (isExecUser && isExecCalendar && isSameUser(matchingExecUserCalendar, selectedUser)) {
          // if same user -> true
          return true;
        }
      }
      return isSameEmail(
        getCalendarUserEmail(c),
        getUserEmail(selectedUser),
      );
    });
  } else {
    // TODO: check this function for templates -> sketchy
    matchingCalendars.forEach((c) => {
      if (!matchingCalendar) {
        matchingCalendar = c;
        return;
      }
      if (isVimcalEAUser && isCalendarExecutiveCalendar({calendar: c, allLoggedInUsers})) {
        matchingCalendar = c;
        return;
      }
      if (getCalendarIsPrimary(c)) {
        matchingCalendar = c;
        return;
      }
    });
  }

  return isValidCalendar(matchingCalendar) ? matchingCalendar : null;
}

export function getSelectedCalendarIndexFromAllCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return {};
  }

  let selectedCalendarIndex = {};

  Object.keys(allCalendars).forEach((key) => {
    selectedCalendarIndex[key] = isCalendarSelected(allCalendars[key]);
  });

  return selectedCalendarIndex;
}

export function toggleOffAllCalendars({ allCalendars, exceptionList = [] }) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return {};
  }

  let updatedAllCalendars = {};
  Object.keys(allCalendars).forEach((k) => {
    updatedAllCalendars[k] = addCalendarSelected({
      calendar: allCalendars[k],
      selected: exceptionList.includes(k),
    });
  });

  return updatedAllCalendars;
}

export function isCalendarOutlookCalendar(calendar) {
  return getCalendarObject(calendar)?.provider === CALENDAR_PROVIDERS.OUTLOOK;
}

export function isGoogle(calendar) {
  return getCalendarObject(calendar)?.provider === CALENDAR_PROVIDERS.GOOGLE;
}

export function getOutlookConferencingReactSelectOptions(calendar) {
  // returns array of options for react-select
  const conferencingOptions = getCalendarAllowedMeetingProviders(
    calendar,
  )?.filter((a) => !!a);
  if (!conferencingOptions || conferencingOptions.length === 0) {
    return [];
  }

  return conferencingOptions.map((option) => {
    return {
      value: option,
      label: convertOutlookConferencingToHumanReadable(option),
    };
  });
}

export function getSelectedCalendarEmails(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  return Object.values(allCalendars)
    .filter((calendar) => isCalendarSelected(calendar))
    .map((calendar) => getCalendarProviderId(calendar));
}

export function isDefaultOutlookCalendar(calendar) {
  return (
    getCalendarObject(calendar)?.isDefaultCalendar ??
    getCalendarObject(calendar)?.raw_json?.isDefaultCalendar
  );
}

export function getOutlookCalendarDefaultOnlineMeetingProvider(calendar) {
  return (
    getCalendarObject(calendar)?.defaultOnlineMeetingProvider ??
    getCalendarObject(calendar)?.raw_json?.defaultOnlineMeetingProvider
  );
}

export function getCalendarsGroupedByUserEmail({ allCalendars, currentUser }) {
  let orderedUserEmails = [];
  const indexByUserEmail = {};

  if (isEmptyObjectOrFalsey(allCalendars)) {
    return { orderedUserEmails, indexByUserEmail };
  }

  Object.values(allCalendars).forEach((calendar) => {
    if (isEmptyObjectOrFalsey(calendar)) {
      return;
    }

    const { userEmail } = calendar;

    if (indexByUserEmail[userEmail]) {
      if (getCalendarIsPrimary(calendar)) {
        // put primary email at the top
        indexByUserEmail[userEmail] = [calendar].concat(
          indexByUserEmail[userEmail],
        );
      } else {
        indexByUserEmail[userEmail] =
          indexByUserEmail[userEmail].concat(calendar);
      }
    } else if (userEmail) {
      indexByUserEmail[userEmail] = [calendar];
    }
  });

  const userEmails = Object.keys(indexByUserEmail);

  userEmails.forEach((email) => {
    if (email === currentUser.email) {
      orderedUserEmails = [email].concat(orderedUserEmails);
    } else {
      orderedUserEmails = orderedUserEmails.concat(email);
    }
  });

  return { orderedUserEmails, indexByUserEmail };
}

export function getAllCalendarEmails(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  const allEmails = Object.values(allCalendars)
    .map((calendar) => getCalendarEmail(calendar))
    .filter((email) => !!email);
  return removeDuplicatesFromArray(allEmails);
}

export function getEmailsFromMainCalendars({
  userMainCalendars,
  userCalendarID,
}) {
  // mainCalendars is from master account and is array
  if (isEmptyArray(userMainCalendars) || !userCalendarID) {
    return null;
  }
  let name;
  userMainCalendars.forEach((item) => {
    item?.main_calendars?.forEach((calendar) => {
      if (name) {
        return;
      }
      if (calendar?.user_calendar_id === userCalendarID) {
        if (calendar?.name) {
          name = calendar.name;
        } else if (calendar.provider_id === PROVIDER_TYPES.OUTLOOK) {
          name = calendar.user_email;
        } else {
          name = calendar.google_id;
        }
      }
    });
  });
  return name;
}

export function getMatchingPrimaryCalendarForUser({ allCalendars, user }) {
  const matchingCalendars = getMatchingCalendarsForUser({ allCalendars, user });
  return Object.values(matchingCalendars).find((calendar) =>
    getCalendarIsPrimary(calendar),
  );
}

/**
 * @param {Object} options
 * @param {User | null=} options.user
 * @param {string | null=} options.userEmail
 * @param {import("../services/stores/SharedAccountData").AllCalendarsState["allCalendars"]} options.allCalendars
 * @return {import("../services/stores/SharedAccountData").AllCalendarsState["allCalendars"]}
 */
export function getMatchingCalendarsForUser({ allCalendars, user, userEmail }) {
  const filteredAllCalendars = {};
  Object.keys(allCalendars).forEach((userCalendarID) => {
    const calendar = allCalendars[userCalendarID];
    if (
      isSameEmail(
        getCalendarUserEmail(calendar),
        (userEmail || getUserEmail(user)),
      )
    ) {
      filteredAllCalendars[userCalendarID] = calendar;
    }
  });
  return filteredAllCalendars;
}

export function getMatchingCalendarsForUserAsAList({ allCalendars, user }) {
  const matchingCalendars = getMatchingCalendarsForUser({ allCalendars, user });
  return immutablySortArray(Object.values(matchingCalendars), (a, b) => {
    if (getCalendarIsPrimary(a) && !getCalendarIsPrimary(b)) {
      return -1;
    }
    if (!getCalendarIsPrimary(a) && getCalendarIsPrimary(b)) {
      return 1;
    }
    if (isSameEmail(getCalendarEmail(a), getUserEmail(user))) {
      return -1;
    }
    if (isSameEmail(getCalendarEmail(b), getUserEmail(user))) {
      return -1;
    }
    if (getCalendarEmail(a) < getCalendarEmail(b)) {
      return 1;
    }
    if (getCalendarEmail(a) > getCalendarEmail(b)) {
      return -1;
    }
    return 0;
  });
}

/**
 * For now, checking if the email has a custom domain is the most straight-forward way
 * to see if the user is a Workspace user.
 */
export function isGoogleWorkspaceCalendar(calendar) {
  if (!calendar || !isGoogle(calendar)) {
    return false;
  }

  const email = getCalendarUserEmail(calendar);
  return !lowerCaseAndTrimString(email).endsWith("@gmail.com");
}

export function doesCalendarHaveCategories({ calendar, outlookCategories }) {
  if (!isCalendarOutlookCalendar(calendar) || isEmptyObjectOrFalsey(outlookCategories)) {
    return false;
  }
  const ownerEmail = getCalendarOwnerEmail(calendar);
  return !isEmptyArrayOrFalsey(outlookCategories[ownerEmail]);
}

export function convertUserCalendarIDToProviderIDs({
  userCalendarIDs,
  allCalendars,
}) {
  if (isEmptyArray(userCalendarIDs) || isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  return userCalendarIDs
    .map((userCalendarID) => {
      const matchingCalendar = getCalendarFromUserCalendarID({
        userCalendarID,
        allCalendars,
      });
      return getCalendarProviderId(matchingCalendar);
    })
    .filter((providerID) => !!providerID);
}

// given a list of userCalendarIDs and user, returns the providerIDs for the user
export function getMatchingUserProviderIDsFromUserCalendarIDs({
  userCalendarIDs,
  userEmail,
  allCalendars,
}) {
  if (
    isEmptyArrayOrFalsey(userCalendarIDs) ||
    !userEmail ||
    isEmptyObjectOrFalsey(allCalendars)
  ) {
    return [];
  }
  return userCalendarIDs
    .map((userCalendarID) =>
      getCalendarFromUserCalendarID({
        userCalendarID,
        allCalendars,
      }),
    )
    .filter((calendar) =>
      isSameEmail(getCalendarUserEmail(calendar), userEmail),
    )
    .map((calendar) => getCalendarProviderId(calendar));
}

// writableCalendars is an object {userCalendarID: writableCalendar}
export function findBestGuessWritableCalendar({
  writableCalendars,
  currentUser,
  masterAccount,
  checkForPrimaryOnly = false,
  allLoggedInUsers,
  skipCheckForMatchingDelegateSecondaryCalendar = true,
  allCalendars,
}) {
  if (isEmptyObjectOrFalsey(writableCalendars)) {
    return null;
  }
  const calendars = Object.values(writableCalendars);

  const currentUserEmail = getUserEmail(currentUser);
  const primaryWritableCalendarForCurrentUser = calendars.find(
    (calendar) =>
      getCalendarIsPrimary(calendar) &&
      isCalendarSelected(calendar) &&
      isSameEmail(currentUserEmail, getCalendarUserEmail(calendar)),
  );
  if (primaryWritableCalendarForCurrentUser) {
    return primaryWritableCalendarForCurrentUser;
  }
  if (isUserMaestroUser(masterAccount)) {
    const executiveCalendars = calendars.find((calendar) => {
      if (!isCalendarSelected(calendar)) {
        return false;
      }
      if (!getCalendarIsPrimary(calendar)) {
        return false;
      }
      const matchingUser = getMatchingUserFromAllUsers({
        allUsers: allLoggedInUsers,
        userEmail: getCalendarUserEmail(calendar),
      });
      return isUserDelegatedUser(matchingUser);
    });
    if (executiveCalendars) {
      return executiveCalendars;
    }
  }
  const primaryWritableSelectedCalendar = calendars.find(
    (calendar) => getCalendarIsPrimary(calendar) && isCalendarSelected(calendar),
  );
  if (primaryWritableSelectedCalendar) {
    // if primary is selected, return that
    return primaryWritableSelectedCalendar;
  }

  // only check secondary calendars if Vimcal EA
  if (!skipCheckForMatchingDelegateSecondaryCalendar && isUserMaestroUser(masterAccount)) {
    // see if a secondary calendar is that of a delegated calendar
    // we can start by checking primary for now.
    const primaryCalendarProviders = calendars.filter(c => {
      return (getCalendarIsPrimary(c) || isCalendarExecutiveCalendar({calendar: c, allLoggedInUsers}))
        && !shouldHideDelegatedUserCalendar({calendar: c, allCalendars, allLoggedInUsers});
    });
    const primaryCalendarProviderIDs = primaryCalendarProviders.map(c => getCalendarProviderId(c));
    const toggledOnWritableCalendar = calendars.find((calendar) =>
      isCalendarSelected(calendar) && primaryCalendarProviderIDs.includes(getCalendarProviderId(calendar)),
    );
    if (toggledOnWritableCalendar) {
      return toggledOnWritableCalendar;
    }
  }

  if (checkForPrimaryOnly) {
    return;
  }
  const toggledOnWritableCalendar = calendars.find((calendar) =>
    isCalendarSelected(calendar),
  );
  return toggledOnWritableCalendar;
}

export function filterForAllWritableCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return {};
  }
  const writableCalendars = {};
  Object.keys(allCalendars).forEach((userCalendarID) => {
    const calendar = allCalendars[userCalendarID];
    if (doesCalendarHaveEditableRole(calendar)) {
      writableCalendars[userCalendarID] = calendar;
    }
  });
  return writableCalendars;
}

export function getTimeZoneFromCalendar(calendar) {
  // value is from the calendar's raw_json
  return getCalendarObject(calendar)?.time_zone;
}

export function getPopularTimeZoneFromCalendar(calendar) {
  // value is from our backend
  return getCalendarObject(calendar)?.[POPULAR_TIME_ZONE];
}

export function getUpdatedUserTimeZonesIndexAndLastSet({
  allCalendars,
}) {
  const newUserTimeZonesIndex = {};
  const newUserTimeZoneLastSetIndex = {};
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return {
      newUserTimeZonesIndex,
      newUserTimeZoneLastSetIndex,
    };
  }
  Object.values(allCalendars).forEach((calendar) => {
    const timeZone = getPopularTimeZoneFromCalendar(calendar);
    if (isValidTimeZone(timeZone)) {
      const key = getCalendarEmail(calendar);
      newUserTimeZonesIndex[key] = timeZone;
      newUserTimeZoneLastSetIndex[key] = (new Date()).toISOString();
    }
  });
  return {
    newUserTimeZonesIndex,
    newUserTimeZoneLastSetIndex,
  };
}

export function getCalendarUserOrder(calendar) {
  try {
    const order = getCalendarObject(calendar)?.user_order;
    if (!isInt(order)) {
      return null;
    }
    return order;
  } catch (error) {
    return null;
  }
}

export async function updateCalendarOrder({
  orderedCalendars,
  user,
}) {
  if (isEmptyObjectOrFalsey(orderedCalendars)) {
    return;
  }
  const url = constructRequestURLV2(UPDATE_CALENDAR_ORDER);

  const userCalendarOrder = {};
  orderedCalendars.forEach((calendar, index) => { userCalendarOrder[getCalendarUserCalendarID(calendar)] = index; });

  const body = {
    user_calendar_order: userCalendarOrder,
  };
  const payloadData = {
    body: JSON.stringify(body),
  };

  return await fetcherPost({
    url,
    payloadData,
    email: getUserEmail(user),
  });
}

export function isSameCalendarUserCalendarID(calendarA, calendarB) {
  return getCalendarUserCalendarID(calendarA) === getCalendarUserCalendarID(calendarB);
}

export function isSameCalendarProviderID(calendarA, calendarB) {
  return getCalendarProviderId(calendarA) === getCalendarProviderId(calendarB);
}

export function getUnselectedCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }
  return Object.values(allCalendars).filter((calendar) => !isCalendarSelected(calendar));
}

export function getActiveSelectedCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }
  return Object.values(allCalendars).filter((calendar) => isCalendarSelected(calendar));
}

export function getAllOutlookCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }
  return Object.values(allCalendars).filter((calendar) => isCalendarOutlookCalendar(calendar));
}

export function doesAllCalendarsContainOutlookCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return false;
  }
  return Object.values(allCalendars).some((calendar) => isCalendarOutlookCalendar(calendar));
}

// returns an object {email: order}
// if there are multiple calendars for the same email, the lowest order is used
export function getEmailToCalendarOrderIndex({emails, allCalendars}) {
  if (isEmptyArrayOrFalsey(emails) || isEmptyObjectOrFalsey(allCalendars)) {
    return {};
  }
  const emailToCalendarOrderIndex = {};
  const allCalendarsArray = Object.values(allCalendars);

  emails?.forEach((email) => {
    const calendars = allCalendarsArray.filter((calendar) => isSameEmail(getCalendarEmail(calendar), email));
    const order = immutablySortArray(calendars.filter(c => doesCalendarHaveOrder(c)).map(c => getCalendarUserOrder(c))).shift();
    emailToCalendarOrderIndex[email] = order;
  });
  return emailToCalendarOrderIndex;
}

export function isCalendarColorDefaultOutlookColor(calendar) {
  return equalAfterTrimAndLowerCased(getCalendarColorHex(calendar), DEFAULT_OUTLOOK_CALENDAR_HEX);
}

export function doesCalendarHaveEditableRole(calendar) {
  return EDITABLE_ROLES.includes(getCalendarEditRole(calendar));
}

export function isResourceCalendarEmail(calendarEmail) {
  if (!calendarEmail) {
    return false;
  }
  return calendarEmail.includes("google") && calendarEmail.indexOf("group") > calendarEmail.indexOf("@");
}

export function orderMainCalendarEvents(events) {
  if (isEmptyArrayOrFalsey(events)) {
    return [];
  }
  return immutablySortArray(events, (a, b) => {
    try {
      const aID = `${getEventTitle(a) || "No Title"} ?? ${getEventUserEventID(a) || "No ID"}`;
      const bID = `${getEventTitle(b) || "No Title"} ?? ${getEventUserEventID(b) || "No ID"}`;

      return aID.localeCompare(bID);
    } catch (error) {
      return 0;
    }
  });
}

export function isGoogleBirthdayCalendar(calendar) {
  try {
    return getCalendarName({calendar})?.trim() === "Birthdays" && isGoogle(calendar) && isResourceCalendarEmail(getCalendarEmail(calendar));
  } catch (error) {
    return false;
  }
}

export function filterOutBirthdayCalendar(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return {};
  }

  // immutably filter
  return Object.fromEntries(
    Object.entries(allCalendars).filter(([key, calendar]) => !isGoogleBirthdayCalendar(calendar)),
  );
}

export function getCalendarCountFromAllCalendars(allCalendars) {
  try {
    return Object.keys(allCalendars)?.length || 0;
  } catch (error) {
    return 0;
  }
}

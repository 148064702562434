import {
  getFirstDayOfMonthlyCalendarJSDate,
  getLastDayOfMonthlyCalendarJSDate,
  convertDateIntoEpochUnixWeek,
  getFirstDayOfWeekJsDate,
  getLastDayOfWeekJsDate,
  localData,
  isValidJSDate,
  guessTimeZone,
  isValidTimeZone,
  createAbbreviationForTimeZone,
  createAmPmBarWithHourDiff,
  isOnboardingMode,
  getSearchParams,
  isBeforeDay,
  LOCAL_STORAGE_KEYS,
  convertTrueFalseStringIntoValue,
} from "../services/commonUsefulFunctions";
import {
  MOMENT_DMY_DATE_FORMAT,
} from "../services/googleCalendarService";
import {
  startOfMonth,
  endOfMonth,
  subWeeks,
  addWeeks,
  parse,
  startOfWeek,
  getDay,
  differenceInCalendarDays,
  subDays,
  addDays,
  isSameDay,
  subMonths,
  addMonths,
} from "date-fns";
import {
  ROLLING_SEVEN_DAY,
  BACKEND_4_DAY_VIEW,
  BACKEND_MONTH,
  LOGGED_IN_ACCOUNTS,
  DEFAULT_RECENT_TIMEZONES,
  SECOND_IN_MS,
  DEFAULT_FREE_TRIAL_LENGTH,
  BackendCalendarView,
} from "../services/globalVariables";
import { parseISO, format } from "date-fns";
import { dateFnsLocalizer } from "rbc-fork-react-big-calendar";
import {
  ALL_CANCELLED_STATE,
  ACCOUNT_STATE_TRIAL,
  ACCOUNT_STATE_NEW_USER,
  ACCOUNT_STATE_PAYING,
  PRODUCT_HUNT_PROMOTION,
  HUSTLE_FUND_PROMOTION,
  WISHU_PROMOTION,
  FAILSAFE_PROMOTION,
  LEGACY_YC_PROMOTION,
  EARLY_ACCESS_PROMOTION,
  ACCOUNT_STATE_ONBOARDING,
  ACCOUNT_STATE_BACKSTAGE,
  ACCOUNT_STATE_PREPAID,
  ACCOUNT_STATE_REFERRED,
  ACCOUNT_STATE_VIP,
  ACCOUNT_STATE_FREEMIUM,
  STANDARD_YC_PROMO,
  ADMINS_DAY_PROMO,
  WINBACK_PROMO_CODES,
} from "./vimcalVariables";
import { isForcingPrepaidUserToPersonalOnboarding, isSelfServeOpen } from "./featureFlagFunctions";
import {
  doesCalendarHaveEditableRole,
  getCalendarName,
  getCalendarUserEmail,
  getSelectedCalendarIndexFromAllCalendars,
} from "./calendarFunctions";
import {
  getCalendarUserCalendarID,
} from "../services/calendarAccessors";
import { LOCAL_DATA_ACTION, getStoredReferralCode } from "./localData";
import { getCurrentPainterColors, getMasterAccountCreatedAt, getMatchingUserFromAllUsers, getUserEmail, isValidUser } from "./userFunctions";
import { ACCOUNT_STATE_WAIT_LIST } from "./vimcalVariables";
import { TYPEFORM_ROUTE } from "../services/routingFunctions";
import { usePermissionsStore } from "../services/stores/permissionsStore";
import { ACCOUNT_STATE_TRIAL_CHURNED } from "./vimcalVariables";
import { ACCOUNT_STATE_TRIAL_ENDED } from "./vimcalVariables";
import { ACCOUNT_STATE_CANCELLED } from "./vimcalVariables";
import { type StoredCalendar, useAllLoggedInUsers, useMasterAccount } from "../services/stores/SharedAccountData";
import { safeJSONParse } from "./jsonFunctions";
import { filterOutInvalidTimeZones } from "./timeFunctions";
import { getFetchWindowStartAndEnd } from "./syncFunctions";
import { addDefaultToArray, immutablySortArray, removeDuplicatesFromArray } from "./arrayFunctions";
import { filterOutInvalidContacts } from "./contactFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isTypeNumber } from "../services/typeGuards";
import { getAnchorTimeZonesInSettings, getDefaultUserTimeZone, getWorkHours } from "./settingsFunctions";
import { equalAfterTrimAndLowerCased, isSameEmail } from "./stringFunctions";
import { getAccountShowMagicLinkOnboarding } from "../services/maestro/maestroAccessors";
import { Stripe } from "stripe-types";
import { type TruncatedStripeSubscription } from "../types/stripe/truncatedStripe";
import { isUserMaestroUser, shouldHideDelegatedUser, shouldHideDelegatedUserCalendar } from "../services/maestroFunctions";

const LAST_SWITCH_ACCOUNT_TIME = "LAST_SWITCH_ACCOUNT_TIME";
const PERSONAL_CONTACTS_TOKEN = "PERSONAL_CONTACTS_TOKEN";
const OTHER_CONTACTS_TOKEN = "OTHER_CONTACTS_TOKEN";

const MASTER_ACCOUNT = "account_info";
const DEFAULT_PAYMENT_METHOD = "default_payment_method";

export function getDomainUserSyncDate(email: string) {
  return `${email}_domain_user_sync_date`;
}

export function getShouldSkipDomainResourcesSync(email: string) {
  return `${email}_shouldSkipDomainResourcesSync_v1`;
}

function lastFetchOutlookContactTimeToken(user: User) {
  if (!user.email) {
    return
  }
  return `${user?.email}-last-fetch-outlook-contact-time`;
}

function getLastFetchOutlookContactTime(user: User) {
  const token = lastFetchOutlookContactTimeToken(user);
  if (!token) {
    return null;
  }
  const lastFetchOutlookContactTime = localData(LOCAL_DATA_ACTION.GET, token);
  if (!lastFetchOutlookContactTime) {
    return null;
  }

  return parseISO(lastFetchOutlookContactTime);
}

export function saveLastFetchOutlookContactTime(user: User) {
  const token = lastFetchOutlookContactTimeToken(user);
  if (!token) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, token, new Date().toISOString());
}

export function shouldFetchOutlookContact(user: User) {
  const lastFetchTime = getLastFetchOutlookContactTime(user);
  if (!lastFetchTime || !isValidJSDate(lastFetchTime)) {
    return true;
  }

  return !isSameDay(lastFetchTime, new Date());
}

export function createWindow({
  windowJSDate,
  isMonth,
  weekStart,
  selectedCalendarView,
}: {
  windowJSDate: Date,
  isMonth: boolean
  weekStart?: number
  selectedCalendarView: BackendCalendarView
}) {
  // windowDate should be a js Date

  // Window below is to grab the window near today so if you go back to today, it never flickers
  const currentTime = new Date();
  let todayLeftJSDate: Date;
  let todayRightJSDate: Date;

  // Window for agenda where it's always month based
  const windowMonthStartJSDate = getFirstDayOfMonthlyCalendarJSDate(
    windowJSDate,
    weekStart,
  );
  const windowMonthEndJSDate = getLastDayOfMonthlyCalendarJSDate(
    windowJSDate,
    weekStart,
  );

  // Window for mainCalendar
  let windowStartDateJSDate: Date;
  let windowEndDateJSDate: Date;

  // The events window that we fetch from the backend. Whatever is outside of the windowStartDate and windowEndDate goes into Indexdb
  let fetchWindowStartJSDateGoogle: Date; // larger for google
  let fetchWindowEndJSDateGoogle: Date;
  let fetchWindowStartJSDateOutlook: Date; // smaller for outlook
  let fetchWindowEndJSDateOutlook: Date;
  let fetchWindowStartJSDate: Date;
  let fetchWindowEndJSDate: Date;

  // Window for trigger moving between month/week
  let movingWindowStartJSDate: Date;
  let movingWindowEndJSDate: Date;

  let dbWindowStartJSDate: Date;
  let dbWindowEndJSDate: Date;

  if (isMonth) {
    todayLeftJSDate = getFirstDayOfMonthlyCalendarJSDate(
      currentTime,
      weekStart,
    );
    todayRightJSDate = getLastDayOfMonthlyCalendarJSDate(
      currentTime,
      weekStart,
    );

    windowStartDateJSDate = subDays(windowMonthStartJSDate, 1);
    windowEndDateJSDate = addDays(windowMonthEndJSDate, 1);

    fetchWindowStartJSDate = subDays(getFirstDayOfMonthlyCalendarJSDate(
      windowJSDate,
      weekStart,
    ), 1);
    fetchWindowEndJSDate = addDays(getLastDayOfMonthlyCalendarJSDate(
      windowJSDate,
      weekStart,
    ), 1);
    fetchWindowStartJSDateGoogle = fetchWindowStartJSDate;
    fetchWindowEndJSDateGoogle = fetchWindowEndJSDate;
    fetchWindowStartJSDateOutlook = fetchWindowStartJSDate;
    fetchWindowEndJSDateOutlook = fetchWindowEndJSDate;

    // don't use getFirstDayOfMonthlyCalendar here since if you're on the 3rd of june and go back a month , it's possible that it doesn't trigger the previous month bar
    movingWindowStartJSDate = startOfMonth(windowJSDate);
    movingWindowEndJSDate = endOfMonth(windowJSDate);

    dbWindowStartJSDate = getFirstDayOfMonthlyCalendarJSDate(subMonths(windowJSDate, 1), weekStart);
    dbWindowEndJSDate = getLastDayOfMonthlyCalendarJSDate(addMonths(windowJSDate, 1), weekStart);
  } else {
    todayLeftJSDate = getFirstDayOfWeekJsDate(
      subWeeks(currentTime, 1),
      weekStart,
    );
    todayRightJSDate = getLastDayOfWeekJsDate(
      addWeeks(currentTime, 2),
      weekStart,
    );

    const {
      weeksBackward,
      weeksForward,
    } = getFetchWindowStartAndEnd({
      windowJSDate,
      weekStart,
      selectedCalendarView,
    });

    windowStartDateJSDate = weeksBackward;
    windowEndDateJSDate = weeksForward;

    fetchWindowStartJSDate = weeksBackward;
    fetchWindowEndJSDate = weeksForward;

    fetchWindowStartJSDateOutlook = fetchWindowStartJSDate;
    fetchWindowEndJSDateOutlook = fetchWindowEndJSDate;
    fetchWindowStartJSDateGoogle = subWeeks(fetchWindowStartJSDate, 1);
    fetchWindowEndJSDateGoogle = addWeeks(fetchWindowEndJSDate, 1);

    if (is4DayView(selectedCalendarView)) {
      movingWindowStartJSDate = subDays(windowJSDate, 4);
      movingWindowEndJSDate = addDays(windowJSDate, 3);
    } else if (isRolling7DayView(selectedCalendarView)) {
      movingWindowStartJSDate = subDays(windowJSDate, 7);
      movingWindowEndJSDate = addDays(windowJSDate, 6);
    } else {
      movingWindowStartJSDate = subWeeks(windowJSDate, 1);
      movingWindowEndJSDate = addWeeks(windowJSDate, 1);
    }
    dbWindowStartJSDate = subWeeks(movingWindowStartJSDate, 1);
    dbWindowEndJSDate = addWeeks(movingWindowEndJSDate, 1);
  }

  const todayLeftWindow = convertDateIntoEpochUnixWeek(todayLeftJSDate);
  const todayRightWindow = convertDateIntoEpochUnixWeek(todayRightJSDate);

  const windowMonthStart = convertDateIntoEpochUnixWeek(windowStartDateJSDate);
  const windowMonthEnd = convertDateIntoEpochUnixWeek(windowEndDateJSDate);

  const windowStartDate = convertDateIntoEpochUnixWeek(windowStartDateJSDate);
  const windowEndDate = convertDateIntoEpochUnixWeek(windowEndDateJSDate);

  const movingWindowStartDate = convertDateIntoEpochUnixWeek(
    movingWindowStartJSDate,
  );
  const movingWindowEndDate = convertDateIntoEpochUnixWeek(movingWindowEndJSDate);

  return {
    todayLeftWindow,
    todayRightWindow,
    windowStartDate,
    windowEndDate,
    movingWindowStartDate,
    movingWindowEndDate,
    windowMonthStart,
    windowMonthEnd,
    fetchWindowStartJSDate,
    fetchWindowEndJSDate,
    fetchWindowStartJSDateOutlook,
    fetchWindowEndJSDateOutlook,
    fetchWindowStartJSDateGoogle,
    fetchWindowEndJSDateGoogle,
    windowStartDateJSDate,
    windowEndDateJSDate,
    movingWindowStartJSDate,
    movingWindowEndJSDate,
    todayLeftJSDate,
    todayRightJSDate,
    dbWindowStartJSDate,
    dbWindowEndJSDate,
  };
}

export function createWritableCalendarList(param: {
  emailToNameIndex: unknown
  allCalendars: Record<string, VimcalCalendar>
  masterAccount: MasterAccount
  currentUser: User
  allLoggedInUsers?: User[]
}) {
  if (isEmptyObjectOrFalsey(param)) {
    return [];
  }

  const {
    emailToNameIndex,
    allCalendars,
    masterAccount,
    currentUser,
    allLoggedInUsers,
  } = param;

  const writableCalendars: { label: string, value: VimcalCalendar, name: string, userEmail: string }[] = [];

  Object.keys(allCalendars).forEach((calendarId) => {
    const matchingCalendar = allCalendars[calendarId];
    const calendarName = getCalendarName({
      calendar: matchingCalendar,
      emailToNameIndex,
      masterAccount,
      currentUser,
    });

    if (doesCalendarHaveEditableRole(matchingCalendar)) {
      if (isUserMaestroUser(masterAccount) && shouldHideDelegatedUserCalendar({
        calendar: matchingCalendar,
        allCalendars,
        allLoggedInUsers,
      })) {
        return;
      }
      writableCalendars.push({
        label: calendarName,
        value: matchingCalendar,
        name: calendarName,
        userEmail: getCalendarUserEmail(matchingCalendar),
      });
    }
  });

  return writableCalendars;
}

// TODO: Identify types.
export function determineCalendarView(selectedCalendarView) {
  if (selectedCalendarView === ROLLING_SEVEN_DAY) {
    return 7;
  }

  return selectedCalendarView;
}

export function setLastSwitchAccountTime() {
  localData(LOCAL_DATA_ACTION.SET, LAST_SWITCH_ACCOUNT_TIME, new Date().toISOString());
}

// TODO: Identify types.
// TODO: Move to another file, not really a state management function.
export function isEventInWindow(event, windowStartEpoch, windowEndEpoch) {
  // windowStart and windowEnd are both unix times
  if (isEmptyObjectOrFalsey(event) || !windowStartEpoch || !windowEndEpoch) {
    return false;
  }

  return (
    event.epochUnixWeek <= windowEndEpoch &&
    event.epochUnixWeekEnd >= windowStartEpoch
  );
}

export function getAllLoggedInAccountDetails() {
  const unformattedLoggedInAccounts = localData(LOCAL_DATA_ACTION.GET, LOGGED_IN_ACCOUNTS);
  return unformattedLoggedInAccounts
    ? safeJSONParse<User[]>(unformattedLoggedInAccounts) ?? []
    : [];
}

export function determineShadedCalendarHours({
  timeZone,
  timeZoneLabel,
  inputDefaultBrowserTimeZone,
  temporaryTimeZones,
  anchorTimeZones,
  defaultTimeZone,
  masterAccount,
  user,
}: {
  timeZone: string
  timeZoneLabel: string
  inputDefaultBrowserTimeZone: string
  temporaryTimeZones: string[]
  anchorTimeZones: string[]
  defaultTimeZone: string
  masterAccount: MasterAccount
  user: User
}) {
  const currentTimeZone = timeZone;

  const defaultBrowserTimeZone = inputDefaultBrowserTimeZone || guessTimeZone();

  if (!timeZoneLabel) {
    return {};
  } else if (doTemporaryTimeZonesExist(temporaryTimeZones)) {
    return createAmPmBarWithTemporaryEvents({
      timeZone,
      anchorTimeZones,
      temporaryTimeZones,
      defaultBrowserTimeZone: inputDefaultBrowserTimeZone,
      defaultTimeZone,
      masterAccount,
      user,
    });
  } else if (anchorTimeZones?.length > 0) {
    // do not show bars when there are multiple anchor time zones
    return {};
  } else if (defaultBrowserTimeZone !== currentTimeZone) {
    return createAmPmBarWithHourDiff({
      timeZone: currentTimeZone,
      otherTimeZone: defaultBrowserTimeZone,
      masterAccount,
      user,
    });
  } else if (defaultBrowserTimeZone === currentTimeZone) {
    if (
      getAllExtraTimeZones({
        anchorTimeZones,
        temporaryTimeZones,
        defaultTimeZone,
      }).length === 0
    ) {
      // same time zone and we don't show other time zone
      return {};
    }
    return createAmPmBarWithHourDiff({
      timeZone: currentTimeZone,
      otherTimeZone: timeZoneLabel,
      masterAccount,
      user,
    });
  } else {
    return {};
  }
}

export function createAmPmBarWithTemporaryEvents({
  timeZone,
  anchorTimeZones,
  temporaryTimeZones,
  defaultBrowserTimeZone,
  defaultTimeZone,
  masterAccount,
  user,
}: {
  timeZone: string
  anchorTimeZones: string[]
  temporaryTimeZones: string[]
  defaultBrowserTimeZone: string
  defaultTimeZone: string
  masterAccount: MasterAccount
  user: User
}) {
  // want to return object with start and end {start, end};
  const allTimeZones = getAllExtraTimeZones({
    anchorTimeZones,
    temporaryTimeZones,
    defaultTimeZone,
    isGetAllGutterTimeZones: true,
  }).concat(defaultBrowserTimeZone);

  const {
    startWorkHour,
    endWorkHour,
  } = getWorkHours({ masterAccount, user });

  // loop through and mark off hours
  let startShade = startWorkHour;
  let endShade = endWorkHour;
  allTimeZones.forEach((tz) => {
    if (tz === timeZone) {
      return;
    }
    const { start: startBar, end: endBar } = createAmPmBarWithHourDiff({
      timeZone,
      otherTimeZone: tz,
      masterAccount,
      user,
    });
    if (isTypeNumber(startBar)) {
      startShade = Math.max(startBar, startShade);
    }
    if (isTypeNumber(endBar)) {
      endShade = Math.min(endBar, endShade);
    }
  });
  if (startShade >= endShade) {
    return {start: 12, end: 12}; // completely shaded
  }

  return { start: startShade, end: endShade };
}

export function determineLocale(dateFormat: string) {
  if (dateFormat === MOMENT_DMY_DATE_FORMAT) {
    return { "en-GB": require("date-fns/locale/en-GB") };
  } else {
    return { "en-US": require("date-fns/locale/en-US") };
  }
}

export function determineRBCLocalizer(startOfWeekIntger: 0 | 1 | 2 | 3 | 4 | 5 | 6, dateFieldOrder: string) {
  // https://github.com/jquense/react-big-calendar/issues/1837
  return dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () =>
      startOfWeek(new Date(), { weekStartsOn: startOfWeekIntger }),
    getDay,
    locales: determineLocale(dateFieldOrder),
  });
}

export function getCalendarIdFromEmail(email: string): string {
  return localData(LOCAL_DATA_ACTION.GET, `${email}_email_calendarId`) || "";
}

export function setCalendarIdBasedOnEmail(email: string, calendarId: string) {
  if (!email || !calendarId) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, `${email}_email_calendarId`, calendarId);
}

export function getDefaultCalendarRemindersWithCalendarId(calendarId: string) {
  return localData(LOCAL_DATA_ACTION.GET, `${calendarId}_calendarId_default_reminders`);
}

export function setDefaultCalendarRemindersWithCalendarId(
  calendarId: string,
  defaultReminders: unknown,
) {
  if (!calendarId || isEmptyObjectOrFalsey(defaultReminders)) {
    return;
  }

  localData(
    LOCAL_DATA_ACTION.SET,
    `${calendarId}_calendarId_default_reminders`,
    JSON.stringify(defaultReminders),
  );
}

const contactVersion = 1; // update when we need to update how we parse contacts
function getContactLastSyncKey(user: User) {
  return `fetch_all_contacts_updated_at_${user.email}-${contactVersion}`;
}

export function getContactLastSyncTime(user: User) {
  return localData(LOCAL_DATA_ACTION.GET, getContactLastSyncKey(user));
}

export function setContactLastSyncTime(user: User) {
  localData(LOCAL_DATA_ACTION.SET, getContactLastSyncKey(user), new Date().toISOString());
}

function getPersonalContactKey(currentUser: User) {
  return `${currentUser.email}_${PERSONAL_CONTACTS_TOKEN}-${contactVersion}`;
}

function getOtherContactKey(currentUser: User) {
  return `${currentUser.email}_${OTHER_CONTACTS_TOKEN}-${contactVersion}`;
}

export function storePersonalContactsSyncToken(token: string, currentUser: User) {
  if (isEmptyObjectOrFalsey(currentUser) || !token) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, getPersonalContactKey(currentUser), token);
}

export function getPersonalContactsSyncToken(currentUser: User): string {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return "";
  }

  return (
    localData(LOCAL_DATA_ACTION.GET, getPersonalContactKey(currentUser)) || ""
  );
}

export function clearPersonalContactsSyncToken(currentUser: User) {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return;
  }

  localData(LOCAL_DATA_ACTION.DELETE, getPersonalContactKey(currentUser));
}

export function storeOtherContactsSyncToken(token: string, currentUser: User) {
  if (isEmptyObjectOrFalsey(currentUser) || !token) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, getOtherContactKey(currentUser), token);
}

export function getOtherContactsSyncToken(currentUser: User): string {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return "";
  }

  return localData(LOCAL_DATA_ACTION.GET, getOtherContactKey(currentUser)) || "";
}

export function clearOtherContactsSyncToken(currentUser: User) {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return;
  }

  localData(LOCAL_DATA_ACTION.DELETE, getOtherContactKey(currentUser));
}

export function getAnchorTimeZones(anchorTimeZones: string[], defaultBrowserTimeZone: string) {
  if (!anchorTimeZones || anchorTimeZones.length === 0) {
    return [defaultBrowserTimeZone || guessTimeZone()];
  }

  const filteredTimeZone = anchorTimeZones
    .filter((tz) => tz !== defaultBrowserTimeZone)
    .slice(-4);

  return removeDuplicatesFromArray(
    [defaultBrowserTimeZone || guessTimeZone()].concat(filteredTimeZone),
  );
}

// time zones that are always showing
export function getPermanentTimeZones({
  anchorTimeZones,
  defaultTimeZone = guessTimeZone(),
}: {
  anchorTimeZones: string[]
  defaultTimeZone?: string
}) {
  let permanentTimeZones: string[] = [];
  if (!isOnboardingMode() && anchorTimeZones?.length > 0) {
    permanentTimeZones = permanentTimeZones.concat(anchorTimeZones);
  }

  // remove the device OS timezone from list
  permanentTimeZones = permanentTimeZones.filter((tz) => tz !== defaultTimeZone);

  return removeDuplicatesFromArray(permanentTimeZones).slice(-4);
}

export function getAllExtraTimeZones({
  anchorTimeZones,
  temporaryTimeZones,
  defaultTimeZone = guessTimeZone(),
  isForSlots,
  isGetAllGutterTimeZones, // get the exact timezones that are showing in the gutter
  currentTimeZone,
}: {
  anchorTimeZones: string[]
  temporaryTimeZones: string[]
  defaultTimeZone?: string
  isForSlots?: boolean
  isGetAllGutterTimeZones?: boolean
  currentTimeZone?: string
}) {
  const permanentTimeZones = getPermanentTimeZones({
    anchorTimeZones,
    defaultTimeZone,
  });
  if (temporaryTimeZones?.length > 0) {
    // if there's temporary timezone -> always show those
    const allTemporaryTimeZones = temporaryTimeZones.filter((tz) => tz !== defaultTimeZone).slice(-4);
    if (isForSlots || isGetAllGutterTimeZones) {
      return removeDuplicatesFromArray([defaultTimeZone].concat(allTemporaryTimeZones));
    }
    return removeDuplicatesFromArray(permanentTimeZones.concat(allTemporaryTimeZones));
  }

  if (isForSlots) {
    // if there's no temporary timezone, and we're in slots -> return nothing
    if (isGetAllGutterTimeZones) {
      return removeDuplicatesFromArray([currentTimeZone].concat(permanentTimeZones).concat(defaultTimeZone));
    }
    if (currentTimeZone && currentTimeZone !== defaultTimeZone) {
      // selected different anchor time zone but did not add temporary time zones
      return [currentTimeZone];
    }

    return [defaultTimeZone];
  }

  return permanentTimeZones;
}

export function doTemporaryTimeZonesExist(temporaryTimeZones: string[]) {
  return temporaryTimeZones?.length > 0;
}

export function getMostLeftHandTimeZone({
  lastSelectedTimeZone,
  defaultBrowserTimeZone,
  temporaryTimeZones,
  skipCheckForTemporaryTimeZones,
}: {
  lastSelectedTimeZone: string
  defaultBrowserTimeZone: string
  temporaryTimeZones: string[]
  skipCheckForTemporaryTimeZones: boolean
}): string {
  if ((skipCheckForTemporaryTimeZones || doTemporaryTimeZonesExist(temporaryTimeZones)) && isValidTimeZone(lastSelectedTimeZone)) {
    return lastSelectedTimeZone;
  }
  if (isValidTimeZone(defaultBrowserTimeZone)) {
    return defaultBrowserTimeZone;
  }
  return guessTimeZone();
}

export function getRecentlySearchedTimeZones(currentUser: User) {
  const recentTimeZones = currentUser?.recent_time_zones;
  if (isEmptyArrayOrFalsey(recentTimeZones)) {
    return DEFAULT_RECENT_TIMEZONES;
  }
  return recentTimeZones.filter(val => isValidTimeZone(val.value));
}

export function getRecentTimeZoneGroups(currentUser: User) {
  if (isEmptyArrayOrFalsey(currentUser?.recent_time_zone_groups)) {
    return [];
  }
  const groups = currentUser.recent_time_zone_groups;
  const filteredGroups: User["recent_time_zone_groups"] = [];
  groups.forEach(group => {
    const filteredTimeZones = filterOutInvalidTimeZones(group?.value);
    if (!isEmptyArrayOrFalsey(filteredTimeZones)) {
      filteredGroups.push({
        ...group,
        ...{value: filteredTimeZones},
      });
    }
  });
  return filteredGroups;
}

// creates a string of time zone abbreviations
export function createTimeZoneGroupLabel(groupedTimeZones: { value: string[] }): string {
  if (isEmptyArrayOrFalsey(groupedTimeZones?.value) || !Array.isArray(groupedTimeZones.value)) {
    return "";
  }
  const tzList = groupedTimeZones.value;
  const abbreviations = tzList
    .filter(tz => isValidTimeZone(tz))
    .map(tz => createAbbreviationForTimeZone(tz))
    .filter(tz => tz);

  switch(abbreviations.length) {
    case 0:
      return "";
    case 1:
      return abbreviations[0];
    case 2:
      return `${abbreviations[0]} & ${abbreviations[1]}`;
    default:
      const last = abbreviations.pop();
      return `${abbreviations.join(", ")} & ${last}`;
  }
}

export function getRecentlySearchedContacts(currentUser: User) {
  if (isEmptyArrayOrFalsey(currentUser?.recent_meet_with_contacts)) {
    return [];
  }
  const {
    recent_meet_with_contacts,
  } = currentUser;
  return filterOutInvalidContacts(recent_meet_with_contacts);
}

export function getRecentContacts(currentUser: User) {
  if (isEmptyArrayOrFalsey(currentUser?.recent_contacts)) {
    return [];
  }
  return filterOutInvalidContacts(currentUser.recent_contacts);
}

export function getCombinedEmailContact(emails: string[], emailToNameIndex: Record<string, string | undefined>) {
  let email = "";
  let name = "";
  let emailTracker: string[] = [];
  const sortedEmails = immutablySortArray(emails);
  sortedEmails.forEach((e, index) => {
    const currentName = emailToNameIndex[e] || e;
    const lowerCaseEmail = e?.toLowerCase().trim();
    if (emailTracker.includes(lowerCaseEmail)) {
      return;
    } else {
      emailTracker = emailTracker.concat(lowerCaseEmail);
    }

    if (index === 0) {
      email = lowerCaseEmail;
      name = currentName;
    } else if (index === emails.length - 1) {
      if (emails.length > 2) {
        name = name + `, & ${currentName}`;
      } else {
        name = name + ` & ${currentName}`;
      }
      email = email + `, ${lowerCaseEmail}`;
    } else {
      name = name + `, ${currentName}`;
      email = email + `, ${lowerCaseEmail}`;
    }
  });

  return { email, name, emailArray: emailTracker };
}

export function setCalendarACL(currentUser: User, aclPermissions: VimcalCalendarACL) {
  if (!currentUser?.email) {
    return;
  }

  localData(
    LOCAL_DATA_ACTION.SET,
    `${currentUser.email}_acl_permissions`,
    JSON.stringify(aclPermissions),
  );
}

export function getCalendarACL(currentUser: User) {
  if (!currentUser?.email) {
    return;
  }

  const unformatted = localData(LOCAL_DATA_ACTION.GET, `${currentUser.email}_acl_permissions`);

  if (!unformatted) {
    return;
  }

  return safeJSONParse<VimcalCalendarACL>(unformatted);
}

export function createDefaultCalendarACL(ownerCalendars: VimcalCalendar[]) {
  // empty array with calendars that are owners
  const ownerCalendarIds = ownerCalendars.map((c) =>
    getCalendarUserCalendarID(c),
  );
  const sharedCalendarIndex: VimcalCalendarACL = {};
  ownerCalendarIds.forEach((c) => {
    sharedCalendarIndex[c] = [];
  });
  return sharedCalendarIndex;
}

export function isUserInFreeTrial(account: OptionalOrEmptyObject<MasterAccount>) {
  return getAccountState(account) === ACCOUNT_STATE_TRIAL;
}

// also pass in masteraccount
export function getDaysLeftOnTrial({ subscription, masterAccount }: {
  subscription: Stripe.Subscription
  masterAccount: OptionalOrEmptyObject<MasterAccount>
}) {
  if (!subscription?.trial_end) {
    // if end date does not exist -> trial is not over
    const createdAt = getMasterAccountCreatedAt(masterAccount);
    if (createdAt) {
      const createdAtJS = parseISO(createdAt);
      const gateDate = new Date(2023, 4, 19); // may 18 2023
      if (isBeforeDay(createdAtJS, gateDate)) {
        return 14;
      }
    }

    return DEFAULT_FREE_TRIAL_LENGTH;
  }

  const daysLeft = differenceInCalendarDays(
    // trial_end is represented in seconds and Date() constructor takes in milliseconds
    new Date(subscription.trial_end * SECOND_IN_MS),
    new Date(),
  );

  if (daysLeft <= 0) {
    return 0;
  }

  return daysLeft;
}

export function isFreeTrialOver(
  masterAccount: OptionalOrEmptyObject<MasterAccount>,
  subscription: Stripe.Subscription | TruncatedStripeSubscription | Record<string, never>,
) {
  if ((ALL_CANCELLED_STATE as Readonly<string[]>).includes(getAccountState(masterAccount) ?? "")) {
    return true;
  }

  if (!subscription?.trial_end) {
    return false;
  }

  const currentTime = Math.floor(Date.now() / SECOND_IN_MS); // Unix timestamp, seconds since the Unix epoch

  return (
    getAccountState(masterAccount) === ACCOUNT_STATE_TRIAL &&
    currentTime > subscription?.trial_end
  );
}

export function storeMasterAccount(masterAccount: OptionalOrEmptyObject<MasterAccount>) {
  if (isEmptyObjectOrFalsey(masterAccount)) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, MASTER_ACCOUNT, JSON.stringify(masterAccount));
}

export function getMasterAccount(account: OptionalOrEmptyObject<MasterAccount>) {
  if (!isEmptyObjectOrFalsey(account)) {
    return account;
  }

  const unformatted = localData(LOCAL_DATA_ACTION.GET, MASTER_ACCOUNT);
  if (!unformatted) {
    return null;
  }
  return safeJSONParse<MasterAccount>(unformatted);
}

export function storeDefaultPaymentMethod(defaultPaymentMethod: DefaultPaymentMethod) {
  if (isEmptyObjectOrFalsey(defaultPaymentMethod)) {
    return;
  }

  localData(
    LOCAL_DATA_ACTION.SET,
    DEFAULT_PAYMENT_METHOD,
    JSON.stringify(defaultPaymentMethod),
  );
}

export function getDefaultPaymentMethod() {
  const unformattedDefaultPaymentMethod = localData(
    LOCAL_DATA_ACTION.GET,
    DEFAULT_PAYMENT_METHOD,
  );
  if (!unformattedDefaultPaymentMethod) {
    return null;
  }

  return safeJSONParse<DefaultPaymentMethod>(unformattedDefaultPaymentMethod);
}

export function getAccountState(account: OptionalOrEmptyObject<MasterAccount>) {
  const masterAccount = getMasterAccount(account);
  return masterAccount?.state;
}

export function isNewOrPreTrialAccount(account: OptionalOrEmptyObject<MasterAccount>) {
  return [
    ACCOUNT_STATE_FREEMIUM,
    ACCOUNT_STATE_NEW_USER,
    ACCOUNT_STATE_ONBOARDING,
    ACCOUNT_STATE_REFERRED,
    ACCOUNT_STATE_TRIAL,
    ACCOUNT_STATE_TRIAL_ENDED,
    ACCOUNT_STATE_WAIT_LIST,
  ].includes(getAccountState(account) ?? "");
}

export function isWaitingToBeOnboarded(account: OptionalOrEmptyObject<MasterAccount>) {
  return [
    ACCOUNT_STATE_WAIT_LIST,
    ACCOUNT_STATE_PREPAID,
    ACCOUNT_STATE_REFERRED,
    ACCOUNT_STATE_FREEMIUM,
  ].includes(getAccountState(account) ?? "");
}

export function userNeedsTypeForm(account: OptionalOrEmptyObject<MasterAccount>) {
  return ACCOUNT_STATE_NEW_USER === getAccountState(account);
}

export function shouldNeverSeeOnboarding(account: OptionalOrEmptyObject<MasterAccount>) {
  return [
    ACCOUNT_STATE_PAYING,
    ACCOUNT_STATE_TRIAL,
    ACCOUNT_STATE_ONBOARDING,
    ACCOUNT_STATE_VIP,
    ACCOUNT_STATE_BACKSTAGE,
    ACCOUNT_STATE_TRIAL_CHURNED,
    ACCOUNT_STATE_TRIAL_ENDED,
    ACCOUNT_STATE_CANCELLED,
  ].includes(getAccountState(account) ?? "");
}

export function isInOnboarding(account: OptionalOrEmptyObject<MasterAccount>) {
  if (isEmptyObjectOrFalsey(account)) {
    return false;
  }

  const masterAccount = getMasterAccount(account);

  /* For magic link users to see onboarding flow */
  if (getAccountShowMagicLinkOnboarding({ masterAccount })) {
    return true;
  }

  if (isBackstage(account)) {
    return false;
  }

  if (!isSelfServeOpen()) {
    // isSelfServeOpen() always return true so users will
    // never enter this condition
    return isWaitingToBeOnboarded(account);
  }

  if (shouldNeverSeeOnboarding(account)) {
    // this is to check if you were personally onboarding already and we don't trust the show_desktop_onboarding state
    return false;
  }

  return shouldShowDesktopOnboarding(masterAccount);
}

export function shouldShowDesktopOnboarding(masterAccount: OptionalOrEmptyObject<MasterAccount>) {
  return Boolean(masterAccount?.show_desktop_onboarding);
}

export function isAccountReferred(account: OptionalOrEmptyObject<MasterAccount>) {
  return getAccountState(account) === ACCOUNT_STATE_REFERRED;
}

export function isAccountPrepaid(account: OptionalOrEmptyObject<MasterAccount>) {
  return getAccountState(account) === ACCOUNT_STATE_PREPAID;
}

export function isBackstage(account: OptionalOrEmptyObject<MasterAccount>) {
  return getAccountState(account) === ACCOUNT_STATE_BACKSTAGE;
}

export function shouldRefreshPage() {
  const urlParams = getSearchParams();
  if (!urlParams || !urlParams.get) {
    return false;
  }
  return urlParams?.get && urlParams.get("ref") === "r";
}

export function isPayingUser(account: OptionalOrEmptyObject<MasterAccount>) {
  const masterAccount = getMasterAccount(account);
  return masterAccount?.state === ACCOUNT_STATE_PAYING;
}

export function getPainterSettingsObject({ user }: { user: User }) {
  const colors = getCurrentPainterColors({ user });
  const colorIndex: Record<string, SmartTag> = {};
  colors.forEach((c) => {
    colorIndex[c.color] = c;
  });

  return colorIndex;
}

export function getTeamInviteToken() {
  return getUrlParam("team");
}

export function getPromotionCode() {
  return getUrlParam("d");
}

export function getReferralCode() {
  const urlParam = getUrlParam("referral_code");
  const localReferralCode = getStoredReferralCode();

  return localReferralCode || urlParam;
}

function getUrlParam(paramName: string) {
  const urlParams = getSearchParams();
  if (!urlParams || !urlParams.get) {
    return null;
  }

  return urlParams?.get && urlParams.get(paramName);
}

export function getUnappliedPromotions(promotions: Promotion[]) {
  if (isEmptyArrayOrFalsey(promotions)) {
    return [];
  }

  return promotions.filter(promotion => !promotion.applied_on);
}

export function getUnseenPromotions(promotions: Promotion[]) {
  if (isEmptyArrayOrFalsey(promotions)) {
    return [];
  }

  return promotions.filter(promotion => !promotion.last_displayed);
}

export function getLastSetPromotionTime(currentUser: User) {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return;
  }

  const unformatted = localData(
    LOCAL_DATA_ACTION.GET,
    `${currentUser.email}_last_set_promotion_time`,
  );
  if (!unformatted) {
    return null;
  }

  return parseISO(unformatted);
}

export function getPromotionType(promotionList: Promotion[]) {
  if (isEmptyArrayOrFalsey(promotionList)) {
    return null;
  }

  // using 0th index because we're assuming backend only returns one promotion.
  // Will change in the future.
  return promotionList[0]?.promo_code;
}

export function getFirstUnseenPromotion(promotionList: Promotion[]) {
  if (isEmptyArrayOrFalsey(promotionList)) {
    return null;
  }

  const unseenPromotion = promotionList.find((p => p.promo_code && !p.last_displayed));
  if (unseenPromotion) {
    return unseenPromotion.promo_code;
  }
  return null;
}

export function isMonthOffPromotion(promotions: Promotion[]) {
  return [
    PRODUCT_HUNT_PROMOTION,
    LEGACY_YC_PROMOTION,
    EARLY_ACCESS_PROMOTION,
    HUSTLE_FUND_PROMOTION,
    WISHU_PROMOTION,
    FAILSAFE_PROMOTION,
    ...WINBACK_PROMO_CODES,
  ].includes(getPromotionType(promotions) ?? "");
}

export function isAdminsDayDiscount(promotions: Promotion[]) {
  return ADMINS_DAY_PROMO === getPromotionType(promotions);
}

export function isYCDiscount(promotions: Promotion[]) {
  return [
    STANDARD_YC_PROMO,
  ].includes(getPromotionType(promotions) ?? "");
}

export function isUserInOnboarding(account: OptionalOrEmptyObject<MasterAccount>) {
  return getMasterAccount(account)?.state === ACCOUNT_STATE_ONBOARDING;
}

export function setLastestAvailabiltyType(currentUser: User, isPersonalLink: boolean) {
  if (isPersonalLink) {
    localData(LOCAL_DATA_ACTION.SET, `${currentUser.email}_lastest_availability_type`, true);
  } else {
    localData(LOCAL_DATA_ACTION.DELETE, `${currentUser.email}_lastest_availability_type`);
  }
}

export function isLatestAvailabiltyTypePersonalLink(currentUser: User) {
  return localData(LOCAL_DATA_ACTION.GET, `${currentUser.email}_lastest_availability_type`);
}

export function getCurrentViewFetchDates({
  selectedCalendarView,
  selectedDate,
  weekStart,
}: {
  selectedCalendarView: BackendCalendarView
  selectedDate: Date
  weekStart: number
}) {
  const date = selectedDate || new Date();
  let fetchStart: Date;
  let fetchEnd: Date;
  if (selectedCalendarView === BACKEND_MONTH) {
    fetchStart = subDays(
      getFirstDayOfWeekJsDate(startOfMonth(date), weekStart),
      1,
    );
    fetchEnd = addDays(getFirstDayOfWeekJsDate(endOfMonth(date), weekStart), 1);
  } else {
    const {
      weeksBackward,
      weeksForward,
    } = getFetchWindowStartAndEnd({
      windowJSDate: date,
      weekStart,
      selectedCalendarView,
    });
    fetchStart = weeksBackward;
    fetchEnd = weeksForward;
  }
  return {
    fetchStart,
    fetchEnd,
  };
}

export function getAccountForEmail(email: string) {
  if (useAllLoggedInUsers?.getState) {
    const matchingStoreAllLoggedInUsers = useAllLoggedInUsers.getState()?.allLoggedInUsers?.find((u) => equalAfterTrimAndLowerCased(u?.email, email));
    if (matchingStoreAllLoggedInUsers) {
      return matchingStoreAllLoggedInUsers;
    }
  }

  // get from localStorage
  const loggedInAccounts = getAllLoggedInAccountDetails();
  if (isEmptyArrayOrFalsey(loggedInAccounts)) {
    return null;
  }

  return loggedInAccounts.find((a) =>
    equalAfterTrimAndLowerCased(a.email, email),
  );
}

export function sortUsers(userList: User[]) {
  if (!userList || userList.length === 0) {
    return [];
  }

  return immutablySortArray(userList, function (a, b) {
    const nameA = a.email.toUpperCase(); // ignore upper and lowercase
    const nameB = b.email.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
}

export function orderLoggedInUsers(allLoggedInUser: User[], currentUserEmail: string) {
  // return ordered account list with current user account at the very front
  if (isEmptyArrayOrFalsey(allLoggedInUser)) {
    return [];
  }

  const loggedInUsersExceptCurrentUser: User[] = [];
  let currentUserAccount: User | null = null;
  allLoggedInUser.forEach((user) => {
    if (!isSameEmail(getUserEmail(user), currentUserEmail)) {
      loggedInUsersExceptCurrentUser.push(user);
    } else {
      currentUserAccount = user;
    }
  });
  const sortedUsers = sortUsers(loggedInUsersExceptCurrentUser);

  if (currentUserAccount) {
    // TODO: Investigate why type casting is necessary.
    return [currentUserAccount as User].concat(sortedUsers);
  } else {
    return sortedUsers;
  }
}

export function updateAllLoggedInUsers(
  currentAllLoggedInUsers: User[],
  listOfNewAllLoggedInUsers: User[],
) {
  if (isEmptyArrayOrFalsey(currentAllLoggedInUsers)) {
    return listOfNewAllLoggedInUsers;
  }

  if (isEmptyArrayOrFalsey(listOfNewAllLoggedInUsers)) {
    return currentAllLoggedInUsers;
  }

  // Algorithm:
  // 1. find all the new users in array and concat them to the end
  // 2. loop through current list of all logged in users and replace in place the ones that are in list of new all logged in users
  const currentAllUserEmails = currentAllLoggedInUsers.map((u) => getUserEmail(u));
  const newUsers = listOfNewAllLoggedInUsers.filter((u) => !currentAllUserEmails.includes(getUserEmail(u)));
  const updatedExistingAllUsers: User[] = [];
  currentAllLoggedInUsers.forEach((user) => {
    const matchingUser = listOfNewAllLoggedInUsers.find((u) => isSameEmail(getUserEmail(u), getUserEmail(user)));
    if (matchingUser) {
      updatedExistingAllUsers.push(matchingUser);
    } else {
      updatedExistingAllUsers.push(user);
    }
  });
  return updatedExistingAllUsers.concat(newUsers).filter(isValidUser);
}

export function storeAllLoggedInUsers(allLoggedInUsers) {
  if (!allLoggedInUsers) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, LOGGED_IN_ACCOUNTS, JSON.stringify(allLoggedInUsers));
}

export function getStoredUserCalendars(currentUserEmail: string) {
  const unformattedUserCalendars = localData(
    LOCAL_DATA_ACTION.GET,
    `${currentUserEmail}_all_calendars`,
  );
  // TODO: Identify type.
  return unformattedUserCalendars ? safeJSONParse(unformattedUserCalendars) : null;
}

export function getStoredDarkMode() {
  const unformattedDarkModeBool = localData(LOCAL_DATA_ACTION.GET, LOCAL_STORAGE_KEYS.IS_DARK_MODE);
  if (!unformattedDarkModeBool) {
    return false;
  }
  if (convertTrueFalseStringIntoValue(unformattedDarkModeBool)) {
    return true;
  }
  return safeJSONParse<boolean>(unformattedDarkModeBool) || false;
}

/// used to determine if we should show personal onboarding or not
export function emphasizePersonalOnboarding() {
  return true;
  const state = usePermissionsStore.getState();
  if (state?.hideFirstPageOfOnboarding) {
    return false;
  }

  const masterAccount = useMasterAccount.getState();
  if (!isForcingPrepaidUserToPersonalOnboarding()
    && isAccountPrepaid(masterAccount?.masterAccount)
  ) {
    return false;
  }

  return true;
}

export function storeCalendarSelectedOverridePerUser(allCalendars: Partial<Record<string, StoredCalendar>>, user: User) {
  // store override index for each user so we can pull it on switch account
  if (!allCalendars || !user) {
    return;
  }
  const overrideIndex = getSelectedCalendarIndexFromAllCalendars(allCalendars);
  localData(LOCAL_DATA_ACTION.SET, getCalendarSelectedOverrideKey(user), JSON.stringify(overrideIndex));
}

function getCalendarSelectedOverrideKey(user: User) {
  return `${user?.email}-calendar-selected-overrides`;
}

export function getCalendarSelectedOverridePerUser(user: User) {
  const unformattedIndex = localData(LOCAL_DATA_ACTION.GET, getCalendarSelectedOverrideKey(user));
  return unformattedIndex ? safeJSONParse(unformattedIndex) : null;
}

export function deleteCalendarSelectedOverridePerUser(user: User) {
  const key = getCalendarSelectedOverrideKey(user);
  localData(LOCAL_DATA_ACTION.DELETE, key);
}

export function isEmailLoggedIn({ email, allLoggedInUsers }: { email: string, allLoggedInUsers: User[] }) {
  if (!email || isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return false;
  }

  return allLoggedInUsers.find((u) => equalAfterTrimAndLowerCased(getUserEmail(u), email));
}

const PROMO_CODE = "pc";
export function savePromoCode(code: Optional<string>) {
  if (!code) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, PROMO_CODE, code);
}

export function getSavedPromoCode() {
  return localData(LOCAL_DATA_ACTION.GET, PROMO_CODE);
}

export function removeSavedPromoCode() {
  localData(LOCAL_DATA_ACTION.DELETE, PROMO_CODE);
}

const TEAM_INVITE_TOKEN = "team_invite_token";
export function saveTeamInviteToken(inviteToken: Optional<string>) {
  if (!inviteToken) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, TEAM_INVITE_TOKEN, inviteToken);
}

export function getSavedTeamInviteToken() {
  return localData(LOCAL_DATA_ACTION.GET, TEAM_INVITE_TOKEN);
}

export function removeSavedTeamInviteToken() {
  localData(LOCAL_DATA_ACTION.DELETE, TEAM_INVITE_TOKEN);
}

const EVENT_FORM_FOCUS_SECTION = "EVENT_FORM_FOCUS_SECTION";
export function setEventFormFocusSection(section: string) {
  if (!section) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, EVENT_FORM_FOCUS_SECTION, section);
}

export function getEventFormFocusSection() {
  return localData(LOCAL_DATA_ACTION.GET, EVENT_FORM_FOCUS_SECTION) as Optional<string>;
}

export function clearEventFormFocusSection() {
  localData(LOCAL_DATA_ACTION.DELETE, EVENT_FORM_FOCUS_SECTION);
}

export function isInWaitingForSignupPage() {
  return window?.location?.pathname?.includes(TYPEFORM_ROUTE);
}

export function is4DayView(selectedCalendarView: BackendCalendarView | number) {
  return selectedCalendarView === BACKEND_4_DAY_VIEW
    || selectedCalendarView === 4;
}

export function isRolling7DayView(selectedCalendarView: BackendCalendarView) {
  return selectedCalendarView === ROLLING_SEVEN_DAY;
}

export function getOrderedPermanentTimeZoneList({
  masterAccount,
  currentUser,
  orderedTimeZones,
}: {
  masterAccount: MasterAccount
  currentUser: User
  orderedTimeZones?: string[]
}) {
  const defaultTimeZone = getDefaultUserTimeZone({masterAccount, user: currentUser});
  const anchorTimeZones = getAnchorTimeZonesInSettings({masterAccount, user: currentUser});

  const timeZoneList = [defaultTimeZone, ...anchorTimeZones];

  // filter out tzs not in time zone
  const filteredList = (orderedTimeZones ?? []).filter(tz => timeZoneList.includes(tz));
  const timeZonesNotInFilteredList = timeZoneList.filter(tz => !filteredList.includes(tz));
  const finalList = filteredList.concat(timeZonesNotInFilteredList);
  if (isEmptyArrayOrFalsey(finalList)) {
    return [defaultTimeZone].concat(anchorTimeZones);
  }
  return finalList;
}

export function getAllTimeZonesInGutter({
  permanentTimeZones,
  temporaryTimeZones,
}: {
  permanentTimeZones: string[]
  temporaryTimeZones: string[]
}) {
  if (temporaryTimeZones?.length > 0) {
    return temporaryTimeZones;
  }
  return permanentTimeZones;
}

export function getFilteredGroupTimeZones({
  groupTimeZone,
  leftHandTimeZone,
}: {
  groupTimeZone?: string[]
  leftHandTimeZone: string
}) {
  const updatedGroupTimeZones = addDefaultToArray(groupTimeZone).filter(tz => tz !== leftHandTimeZone);
  return {
    newTimeZone: updatedGroupTimeZones[updatedGroupTimeZones.length - 1],
    filteredGroupTimeZone: updatedGroupTimeZones,
  };
}

export function shouldUpdateOrderedTimeZonesFromBackend({
  backendUpdatedAt,
  localUpdatedAt,
}: {
  backendUpdatedAt: string
  localUpdatedAt: string
}) {
  if (!backendUpdatedAt) {
    return false;
  }
  if (!localUpdatedAt && backendUpdatedAt) {
    return true;
  }
  return backendUpdatedAt > localUpdatedAt;
}

export function isWinbackPromotion(promotions: Promotion[]) {
  return (WINBACK_PROMO_CODES as Readonly<string[]>).includes(getPromotionType(promotions) ?? "");
}

import { LOCAL_DATA_ACTION } from "../lib/localData";
import { getCalendarObject } from "./calendarAccessors";
import {
  convertTrueFalseStringIntoValue,
  handleError,
  localData,
} from "./commonUsefulFunctions";
import { isUserMaestroUser, shouldHideDelegatedUser, switchOutDelegateUserCalendarsWithMatchingExecutiveCalendars } from "./maestroFunctions";
import { AllCalendarsState } from "./stores/SharedAccountData";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "./typeGuards";

type OptionalUserArray = User[] | null | undefined;

export function getBlockedCalendars({
  user,
  allLoggedInUsers,
  allCalendars,
  masterAccount,
}: {
  user: Optional<User>,
  allLoggedInUsers: OptionalUserArray,
  allCalendars: AllCalendarsState["allCalendars"],
  masterAccount: Optional<MasterAccount>,
}) {
  // returns array of {calendars, user}
  // example of one response below
  // [
  //   {
  //       "user": {
  //           "first_name": "Kenny",
  //           "last_name": "Weveapp",
  //           "full_name": "Kenny Weveapp",
  //           "provider": "outlook",
  //           "email": "kenny@weveapp.com",
  //           "token": "fVMwtTeWcsoV8VPH",
  //           "is_missing_refresh_token": true
  //       },
  //       "calendars": [
  //           {
  //               "user_calendar_id": "ed530608c3370326515c61299e17d74e",
  //               "provider_id": "AAMkAGUwYmM2ODkyLWI2M2EtNGQwZS04OGNlLWQyMTZmNGQ2M2Y4YQBGAAAAAACUQ_5SYy9kSJT1osdO1-qtBwDJH9gyifHWQJDvUxlnnXDRAAAAAAEGAADJH9gyifHWQJDvUxlnnXDRAAGB2Xu7AAA=",
  //               "google_id": "AAMkAGUwYmM2ODkyLWI2M2EtNGQwZS04OGNlLWQyMTZmNGQ2M2Y4YQBGAAAAAACUQ_5SYy9kSJT1osdO1-qtBwDJH9gyifHWQJDvUxlnnXDRAAAAAAEGAADJH9gyifHWQJDvUxlnnXDRAAGB2Xu7AAA=",
  //               "name": "Kenny",
  //               "name_override": null,
  //               "primary": true,
  //               "hidden": false,
  //               "deleted": false,
  //               "raw_json": {
  //                   "id": "AAMkAGUwYmM2ODkyLWI2M2EtNGQwZS04OGNlLWQyMTZmNGQ2M2Y4YQBGAAAAAACUQ_5SYy9kSJT1osdO1-qtBwDJH9gyifHWQJDvUxlnnXDRAAAAAAEGAADJH9gyifHWQJDvUxlnnXDRAAGB2Xu7AAA=",
  //                   "name": "Kenny",
  //                   "color": "auto",
  //                   "owner": {
  //                       "name": "Kenny",
  //                       "address": "kenny@weveapp.com"
  //                   },
  //                   "canEdit": true,
  //                   "canShare": false,
  //                   "hexColor": "",
  //                   "changeKey": "yR/YMonx1kCQ71MZZ51w0QABjEarbA==",
  //                   "isRemovable": true,
  //                   "@odata.context": "https://graph.microsoft.com/v1.0/$metadata#users('58977136-54f6-401b-9ee1-384d825704eb')/calendars/$entity",
  //                   "isDefaultCalendar": true,
  //                   "canViewPrivateItems": true,
  //                   "isTallyingResponses": true,
  //                   "defaultOnlineMeetingProvider": "unknown",
  //                   "allowedOnlineMeetingProviders": [
  //                       "teamsForBusiness"
  //                   ]
  //               },
  //               "calendar_color": "#a6d1f5",
  //               "user_email": "kenny@weveapp.com"
  //           }
  //       ]
  //   }
  // ]
  try {
    const blockedUserAndCalendars = user?.availability_settings?.blocked_calendars;
    if (isEmptyObjectOrFalsey(blockedUserAndCalendars)) {
      return blockedUserAndCalendars;
    }
    if (!isUserMaestroUser(masterAccount)) {
      return blockedUserAndCalendars;
    }
    if (!shouldHideDelegatedUser({user, allCalendars})) {
      return blockedUserAndCalendars;
    }
    if (isEmptyArrayOrFalsey(blockedUserAndCalendars) || isEmptyObjectOrFalsey(allCalendars)) {
      return blockedUserAndCalendars;
    }

    return blockedUserAndCalendars.map(userAndCalendarObject => {
      // @ts-ignore
      const { calendars } = userAndCalendarObject;
      if (isEmptyArrayOrFalsey(calendars)) {
        return userAndCalendarObject;
      }
      const updatedCalendarUserCalendarIDs = switchOutDelegateUserCalendarsWithMatchingExecutiveCalendars({
        userCalendarIDs: calendars.map(calendar => calendar?.user_calendar_id), // do not use getCalendarUserCalendarID because there's no extra .calendar layer
        allCalendars,
        allLoggedInUsers,
      });
      const updatedCalendars = updatedCalendarUserCalendarIDs.map(userCalendarID => getCalendarObject(allCalendars[userCalendarID]));
      return {
        ...blockedUserAndCalendars,
        calendars: updatedCalendars,
      };
    });
  } catch(error) {
    handleError(error);
    return null;
  }
}

export function setHasBookedOnboarding() {
  localData(LOCAL_DATA_ACTION.SET, "hasBookedOnboarding", true);
}

export function hasBookedOnboarding() {
  const hasBooked = localData(LOCAL_DATA_ACTION.GET, "hasBookedOnboarding");
  return convertTrueFalseStringIntoValue(hasBooked);
}

export function hasAccountBeenOnTeamPlan(masterAccount: OptionalOrEmptyObject<MasterAccount>) {
  if (isEmptyObjectOrFalsey(masterAccount)) {
    return false;
  }

  return masterAccount.has_been_on_team_plan;
}

export function getMasterAccountStripeEmail(masterAccount: OptionalOrEmptyObject<MasterAccount>) {
  return masterAccount?.stripe_email;
}
